import React, {  useEffect } from 'react'



import Transition from '../../components/transitions/animate'
import BannerSection from '../../components/common/bannerSection';
import Blogs from '../../components/common/blogs2';


import Testimonials from './testoimonials2';
import Services from './services2';
import IntroSection from './introsection2';
// import ContactFormSection from './contactformsection';
import WhoWeAre from './whoweare';
import Models from './modelslider';

// import smallImage from '../../assets/images/home-banner-2.webp'
import ContactFormSection  from './contactformsection';

import Garauntee from './garauntee';

import DynamicMetaComponent from '../../components/common/meta';



const Home = () => {


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });

  }, [])


  const parallaxData = [
    {
      start: "0px",
      end: "100%",
      easing: [0.25, 0.1, 0.53, 1],
      duration: ".duration-element",
      properties: [



        {
          startValue: 1,
          endValue: 3,
          property: "scale",
        },
        {

          property: "translateY",
          startValue: 1,
          endValue: 200,

        },


      ],
    },
  ];






  return (
    <>      <BannerSection name='home' parallaxData={parallaxData} bgImg={""} heading={""} review={false}
    subtext={"Our top-rated digital marketing experts can help! Contact us today to learn more about partnering with us."} hasForm={true} />
      <DynamicMetaComponent bgImg={""}   pageTitle=" Code Desk | Well Crafted Language of Digital Marketing" pageDescription="Welcome to The Code Desk where data meets strategy to fuel brand success. We're more than a marketing agency; we're dedicated partners in navigating the dynamic marketing landscape with a team of passionate marketing professionals." />
      <main>




      {/* <SponserSection /> */}
      <IntroSection />

      <Services />

      <WhoWeAre />

      {/* <MoreInfo/> */}


      <Models />

      {/* <ProjectsSection /> */}

      {/* <GeneralNotice  title={"Ready to Elevate Your Brand?"} subTitle={"Your brand's visual identity is your most potent asset. Let's make it count. Get in touch with our design team and start the journey to a more captivating brand today."}  destination={'/design'} /> */}

      <ContactFormSection />


      <Garauntee />


      <Testimonials />

      <Blogs bigHeading={"Popular Blogs"} />

      {/* <Faq subHeading={"Your queries are answered!"} /> */}

      </main>


    </>

  )
}

export default Transition(Home)