import React from 'react'
import { SubHeading} from '../../styles/home.styles'
import Slider from "react-slick";
import styled from 'styled-components';
import Book from '../../components/ui/book';
import { Andriod, TabVertical } from '../../styles/responsive';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { models } from '../../data/data';

const ModelsStyled = styled.section`
    padding: 5em 0 12em 0;
  position: relative;
  

  background-color:#F5F5F5;

  ${TabVertical({padding:"5em 0 7em 0"})}
  ${Andriod({padding:"2em 0"})}
  
  .slick-list {
     overflow: visible;
    
  }

  .slick-prev {
   /* left:calc(100% - 6vw); */
   left:calc(100% - 90px);
   right: auto;

   ${Andriod({left:"20px",transform:"translateY(40px)"})};


 }
 .slick-next {
   left: auto;
   right:0em;
  
   ${Andriod({left:"65px",transform:"translateY(40px)"})}



  }

`
const ModelsContainer = styled.div`
    
    width: min( calc(100% - 5vw) , 1400px );
    margin:0 auto ;
    padding: 4em 0% 5em 0%;
    overflow: hidden;
    ${Andriod({padding:"1.5em 0 5em 0"})};
`

const Models= () => {


 

    const settings = {

        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        draggable: false ,
        arrows:true,
        autoplay:true,
       

 
        autoplaySpeed: 2000,
    
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1
              }
            },
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 3
              }
            }
          ]
       
      }


  return (
    <ModelsStyled>
    
        <SubHeading>Our Solutions</SubHeading>
        <ModelsContainer>      
        <Slider {...settings}>
             {models.map((book,index)=>{
                return  <Book {...book}  key={index}  />
             })}

        </Slider>
        </ModelsContainer>
   </ModelsStyled>

  )
}

export default Models