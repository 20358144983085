import { createGlobalStyle } from 'styled-components'
import { Andriod, LG } from './responsive.js'
import left from '../assets/images/left.png';
import right from '../assets/images/right.png';

const GlobalStyles = createGlobalStyle`

        *,
        *::before,

        *::after {
        box-sizing: border-box;
        }
        
        .show-mob {

           position: absolute;
           top: 160px;
           z-index: 20;
           left:30px ;
           width: min( calc(100% - 5vw) , 1320px );
           left: 50%;
           transform: translateX(-50%);
           ${Andriod({ padding: "0 0 0 10px", top: "100px" })}
           ${LG({ width: "min(calc(100% - 5vw), 1600px)",top:"200px",paddingRight:"200px"})}

    
        }


        
       #root::before {
              content: "";
              width: 100%;
              height: 100%;
              background: linear-gradient(to right, #50EA7B, #54BFBF); 
              position: fixed;
              animation: fadeOut 1000ms forwards ease-out ;
              animation-delay: 1000ms;
              z-index: 9999;
              /* transition: all 350ms ease-out; */
        } 

        /* main {
           opacity: 0;
        } */

        h1 {
          font-size: clamp(2rem, 4.2vw, ${({ theme }) => theme.sizes.heading1}rem);
          color: ${({ theme }) => theme.colors.primaryWhite};
          font-weight: 500;
          line-height: 1.1;
          padding-right: 0%;
          letter-spacing: -2%;
          font-family: 'Satoshi', sans-serif;
          margin-top: 0;
          ${Andriod({paddingRight:0,letterSpacing:'-1px'})}
          ${LG({paddingRight:"1%"})}
        }

    
        body{
           overflow: auto !important;
           padding: 0 !important;
           /* margin: 0; */
        }

        html {
          --cursor-color: #54BFBF;
        }

        html.dark-mode {
          --cursor-color: #f5f5f5;
        
        }

        /* Remove default margin */
        body,
        h1,
        h2,
        h3,
        h4,
        p,
        figure,
        blockquote,
        dl,
        dd {
        margin-block-end: 0;
        }

        /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
        ul[role='list'],
        ol[role='list'] {
        list-style: none;
        }

        /* Set core root defaults */
        html:focus-within {
        scroll-behavior: smooth;
        }

        /* Set core body defaults */

        /* A elements that don't have a class get default styles */
        a:not([class]) {
        text-decoration-skip-ink: auto;
        }

        /* Make images easier to work with */
        img,
        picture {
        max-width: 100%;
        display: block;
        }

        /* Inherit fonts for inputs and buttons */
        input,
        button,
        textarea,
        select {
        font: inherit;
        }

        body {
    
            font-family: 'Helvetica Neue', sans-serif;
            min-height: 100vh;  
            text-rendering: optimizeSpeed;
            line-height: 1.5;
            margin: 0;
            display: flex;
            flex-direction: column;
        }

        .lazy-load-image-background {
           width: 100%;
           transition: opacity 250ms ease-in;
        }


        /* These styles will work on Chrome, Edge, and Safari */
        ::-webkit-scrollbar {
          width: 15px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        ::-webkit-scrollbar-thumb {
          background: #50EA7B;
        }

        /* scrollbar-width and scrollbar-color only wovarierks on Firefox and the latest Chrome version */
        html {
          scrollbar-width:5px;
          scrollbar-color:  #50EA7B #f1f1f1;
        }
        .slick-prev, .slick-next{
            bottom: -80px !important;
            top: auto !important;
            border-radius: 50%;
            z-index: 2;
            width: 40px !important;
            height: 40px !important;
            background-position: center;
            background-size: 40px !important;
            background-repeat: no-repeat;
            opacity: 1;
            ${Andriod({ bottom: "-30px !important",width:"30px !important",height:"30px !important",backgroundSize:"30px !important" })};
          
            
        }

          .slick-prev::before, .slick-next::before {
            content: none !important;
          }

          .slick-prev {
            left:32%;
            /* right: auto; */
            background-image: url(${left}) !important;
            ${Andriod({ left: "16px" })};
          }
          .slick-next {
            /* left: auto; */
            right:60%; 
            background-image: url(${right}) !important;
            ${Andriod({ left: "65px" })}
            
          
            }

            .slick-prev:hover, .slick-prev:focus{
              opacity:0.7;
              background-image: url(${left});
              background-position: center;
              background-size: 40px;
              background-repeat: no-repeat;
            }
            
            .slick-next:hover, .slick-next:focus{ 
              opacity:0.7;

              background-position: center;
              background-size: 40px;
              background-repeat: no-repeat;
              background-image: url(${right});
            
          }

        
          .firstLayerSlider {
              max-width: 1200px;
              width: 100%;
              /* height: 300px; */
              /* height: 652px; */
              padding: 50px 50px 0 50px;
              position: relative;
              margin: 0 auto;
              
            ${Andriod({ padding: "30px 15px 0 15px" })}
              /* top: -200px; */
            }
            
            .firstLayerSlider .swiper-slide {
              background-position: center;
              background-size: cover;
              /* width: 300px;
              height: 300px; */
              height: 450px;
              ${Andriod({ height: "400px" })}
              /* height: 100%; */
            }
            
            .firstLayerSlider .swiper-slide img {
              display: block;
              width: 100%;
       
              border-radius:2em;
              object-fit: cover;
              ${Andriod({ height: "80%" })};
            }
            

          .css-6jbxr1  {
            color:#fff;
          }

          

    
  

          .MuiBox-root.css-rwqry9  {
              
          ${Andriod({ width: "auto", padding: "20px 0" })}
          }



       


`
export default GlobalStyles; 