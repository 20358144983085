
import React, { Suspense, useEffect} from 'react'
import { AnimatePresence } from 'framer-motion'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import SmoothScroll from 'smoothscroll-for-websites/SmoothScroll'
import { lazy } from 'react';
import Aos from 'aos'


// import smallImage from '../assets/images/home-banner-2.webp'


// Contexts
import FormProvider from '../context/formContext'

// Layouts
import Header from '../components/layouts/header'
import Footer from '../components/layouts/footer'

// Pages
import Home from '../pages/home/'
// import T3Model from '../pages/t3'
// import C2Modal from '../pages/c2'
// import C3Modal from '../pages/c3'
// import C4Modal from '../pages/c4'
// import C5Modal from '../pages/c5'
// import C6Modal from '../pages/c6'
// import E5Modal from '../pages/e5'
// import Error from '../pages/error'
// import Blog1 from '../pages/blog/blog1'
// import Blog2 from '../pages/blog/blog2'
// import Blog3 from '../pages/blog/blog3'
// import Contact from '../pages/contact'
// import Strategyandplanning from '../pages/strategyandplanning'
// import BrandingCreativeDesign from '../pages/branding'
// import CodingTechnology from '../pages/coding'
// import DigitalMarketing from '../pages/digital-marketing'
// import ContentMarketing from '../pages/content-marketing'
// import Campaigns from '../pages/campaigns'



// import Launch from '../components/common/launch'
import ColorLoader from '../components/ui/colorLoader'
// import { Helmet, HelmetProvider } from 'react-helmet-async';
// import  '../styles/fonts.css'


import  'aos/dist/aos.css'


// Styles
import Theme from '../styles/theme'
import GlobalStyles from '../styles/global'



const T3Model = lazy(() => import('../pages/t3'));
const C2Modal = lazy(() => import('../pages/c2'));
const C3Modal = lazy(() => import('../pages/c3'));
const C4Modal = lazy(() => import('../pages/c4'));
const C5Modal = lazy(() => import('../pages/c5'));
const C6Modal = lazy(() => import('../pages/c6'));
const E5Modal = lazy(() => import('../pages/e5'));
const Blog1 = lazy(() => import('../pages/blog/blog1'));
const Blog2 = lazy(() => import('../pages/blog/blog2'));
const Blog3 = lazy(() => import('../pages/blog/blog3'));
const Contact = lazy(() => import('../pages/contact'));
const Strategyandplanning = lazy(() => import('../pages/strategyandplanning'));
const BrandingCreativeDesign = lazy(() => import('../pages/branding'));
const CodingTechnology = lazy(() => import('../pages/coding'));
const DigitalMarketing = lazy(() => import('../pages/digital-marketing'));
const ContentMarketing = lazy(() => import('../pages/content-marketing'));
const Campaigns = lazy(() => import('../pages/campaigns'));

const RouterContext = () => {

  const location = useLocation()


  useEffect(() => {

    Aos.init({ once: true ,offset:"200",easing:"ease-in-out"})

    SmoothScroll({
      stepSize: 70,
      animationTime: 1000,

    })
    let domReady = (cb) => {
      document.readyState === 'interactive' || document.readyState === 'complete'
        ? cb()
        : document.addEventListener('DOMContentLoaded', cb);
    };
    
    domReady(() => {
      // Display body when DOM is loaded
      console.log("this document is not yet ",document.readyState);
      document.body.style.visibility = 'visible';
      document.body.style.background="#fff";
    });
   
  
  }, [])

  //  useEffect(()=>{
   
  //     console.log(currentLocation[currentLocation.length - 1]);
  //     const pagesTitles = [
  //         {
  //            path:'contact',
  //            title:'Contact Us'
  //         },
  //         {
  //           path:'e5-model',
  //           title:'Your Complete Hub for Premier Digital Marketing Services!'
  //         }
  //     ].filter((pageTitle)=>pageTitle.path===currentLocation[currentLocation.length - 1])
     
  //     if(pagesTitles.length>0){
  //         setTitle(pagesTitles[0].title);
  //     }
      
  //  },[location.pathname])


  return (
    <>
    
    <FormProvider> 
         <Theme>
         <GlobalStyles />
         <Header />
           <Suspense fallback={<ColorLoader />}>
        
          <AnimatePresence mode='wait'>

            <Routes location={location} key={location.key || location.pathname}>
              <Route index element={<Home />} />
              <Route path='/services/strategy-planning' element={<Strategyandplanning />} />
              <Route path='/services/strategy-planning/t3-model' element={<T3Model />} />

              <Route path='/services/branding-creative-design' element={<BrandingCreativeDesign />} />
              <Route path='/services/branding-creative-design/c2-model' element={<C2Modal />} />

              <Route path='/services/coding-technology' element={<CodingTechnology />} />
              <Route path='/services/coding-technology/c3-model' element={<C3Modal />} />

              <Route path='/services/digital-marketing' element={<DigitalMarketing />} />
              <Route path='/services/digital-marketing/c4-model' element={<C4Modal />} />

              <Route path='/services/content-marketing' element={<ContentMarketing />} />
              <Route path='/services/content-marketing/c5-model' element={<C5Modal />} />

              <Route path='/services/campaigns' element={<Campaigns />} />
              <Route path='/services/campaigns/c6-model' element={<C6Modal />} />

              <Route path='/corporate/e5-model' element={<E5Modal />} />

              <Route path='/contact' element={<Contact />} />
              <Route path='/blog/the-essential-role-of-content-marketing' element={<Blog1 />} />
              <Route path='/blog/tips-for-effective-email-marketing-campaigns' element={<Blog2 />} />
              <Route path='/blog/the-rise-of-progressive-web-apps-pwas' element={<Blog3 />} />

              <Route path='*' element={ <Navigate to={'/'}/>} />
            </Routes>

          </AnimatePresence>
          </Suspense>

          <Footer />
       
      </Theme>

    </FormProvider>
    </>
  )
}

export default RouterContext