import React, { useEffect, useRef, useState } from 'react'
import { IntroStyled,  Widget, WidgetContainer } from '../../styles/home.styles'
import WidgetIcon from '../../components/ui/widgets';
import StatBar from '../../components/common/statbar';



const IntroSection = () => {


    const introSectionRef = useRef(null);
    const socialMediaRef = useRef(null);
    const [isScrolled,setIsScrolled] = useState(false);


    useEffect(()=>{
        // Create Method to handle Scroll
        const handleScroll= ()=>{
           if(introSectionRef.current){
         
   
            const sectionTop = introSectionRef.current.offsetTop  - socialMediaRef.current.offsetHeight;
            const scrollPosition = window.scrollY;
   
               if (scrollPosition > sectionTop) {
                  setIsScrolled(true);
             
               } else {
                  setIsScrolled(false);
               }
           }  
        }
   
         // Attach the scroll event listener
          window.addEventListener('scroll', handleScroll);
   
          // Cleanup the event listener on component unmount
          return () => {
                window.removeEventListener('scroll', handleScroll);
         };
    },[])


  return (
     <IntroStyled space="" ref={introSectionRef} >
             <WidgetContainer>
                    <Widget scrolled={isScrolled?"fixed":''} ref={socialMediaRef}>
                         <WidgetIcon/>
                    </Widget>
            </WidgetContainer>
            <StatBar/>
    </IntroStyled>
  )
}

export default IntroSection