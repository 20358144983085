
import { FaRegThumbsUp } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa";
import { GiCog } from "react-icons/gi";

import  testimonialImage1 from "../assets/images/icons/locum-desk.webp";
import  testimonialImage3 from "../assets/images/icons/medfuture.webp";
import nw_ServiceImg1 from '../assets/images/serviceimages/s-p.webp'
import nw_ServiceImg2 from '../assets/images/serviceimages/b-c.webp'
import nw_ServiceImg3 from '../assets/images/serviceimages/c-t.webp'
import nw_ServiceImg4 from '../assets/images/serviceimages/d-m.webp'
import nw_ServiceImg5 from '../assets/images/serviceimages/c-m.webp'
import nw_ServiceImg6 from '../assets/images/serviceimages/c-p.webp'

import t3 from '../assets/images/bookimages/t3.webp'
import c4 from '../assets/images/bookimages/c4.webp'
import c5 from '../assets/images/bookimages/c5.webp'
import e5 from '../assets/images/bookimages/e5.webp'
import c6 from '../assets/images/bookimages/c6.webp'
import c2 from '../assets/images/bookimages/c2.webp'
import c3 from '../assets/images/bookimages/c3.webp'

import e5Img from '../assets/images/e5-big-book.webp'
import c2Img from '../assets/images/bookimages/c2-model.webp'
import c3Img from '../assets/images/bookimages/c3-big.webp'
import c4Img from '../assets/images/bookimages/c4-big.webp'
import c5Img from '../assets/images/bookimages/c5-big.webp'
import c6Img from '../assets/images/bookimages/c6-big.webp'
import t3Image from '../assets/images/bookimages/t3-big.webp'

import helpImage from '../assets/images/serviceimages/branding/help.webp'



// export const services = [
//       {
//          id:1,
//          text:"design",
//          src:designImg,
//          down:false
//       },
//       {
//         id:2,
//         text:"development",
//         src:developmentImg,
//         down:true
//      },
//      {
//         id:3,
//         text:"digital",
//         src:digitalImg,
//         down:false
//      }
// ]

export const services2 = [
    {
      id:1,
      text:"Strategy & Planning",
      src:nw_ServiceImg1,
      down:true,
      middle:false,
      url:'/services/strategy-planning'

    },

    {
      id:2,
      text:"Digital Marketing",
      src:nw_ServiceImg4,
      down:true,
      middle:true,
      url:'/services/digital-marketing'
  },

  {
    id:3,
    text:"Content Marketing",
    src:nw_ServiceImg5,
    down:true,
    middle:false,
    url:'/services/content-marketing'
  },

  {
    id:4,
    text:"Coding & Technology",
    src:nw_ServiceImg3,
    down:true,
    middle:false,
    url:'/services/coding-technology'
  },

  {
    id:5,
    text:"Branding & Creative Design",
    src:nw_ServiceImg2,
    down:true,
    middle:true,
    spacing:true,
    url:'/services/branding-creative-design'
  },

  {
    id:6,
    text:"Campaigns",
    src:nw_ServiceImg6,
    down:true,
    middle:false,
    url:'/services/campaigns'
  },

]

export const stastics = [
    { id:0,
      figure:95,
      sign:'%',
      src:"",
      text:'Client Satisfaction'
    },
    { id:1,
     figure:14,
     sign:'+',
     src:"",
     text:'Complete Projects'
   },
   { id:2,
     figure:1800,
     sign:'+',
     src:"",
     text:'Design Resource'
   }
 ]
 
 export const questions_answers = [

    {
      question:"Why is strategic planning essential?",

      answer:"Strategic planning is essential for organizations as it provides a clear sense of direction, purpose, and a framework for decision-making. It involves setting specific goals, enabling effective resource allocation based on internal and external assessments, and promoting adaptability to changes in the business environment. <br/> <br/>  By regularly reviewing and updating strategies, organizations can stay competitive, leveraging their unique strengths to achieve a competitive advantage. Furthermore, strategic planning facilitates effective communication of organizational objectives, ensuring alignment among team members and stakeholders."
      },

      {
        question:"How does properly designed elements help your business?",

        answer:"Properly designed elements are instrumental in bolstering a business on multiple fronts. A cohesive and visually appealing design cultivates a strong brand image, fostering recognition and trust among customers. In the digital realm, user-friendly interfaces and well-designed products enhance the overall customer experience, contributing to customer satisfaction and loyalty. Effective design also serves as a powerful communication tool, ensuring that messages are clear, impactful, and resonate with the target audience."
    },
    {
      question:"How does development work for your business?",

      answer:"Development is a multifaceted process involving the creation, enhancement, or improvement of systems, products, or capabilities. In the context of software or technology, development often refers to the design, coding, testing, and deployment of applications or systems. It typically follows a systematic approach, encompassing stages such as requirements gathering, planning, coding, testing, and deployment.<br/> Development can also extend beyond the realm of technology to include areas like business development, where strategies and processes are devised to achieve organizational growth. Regardless of the specific domain, successful development involves collaboration among diverse teams, adherence to best practices, continuous iteration, and responsiveness to evolving needs or challenges."
  },
    {
      question:"What is Digital Marketing?",

      answer:"Digital marketing is a comprehensive strategy that leverages various online channels and technologies to promote products, services, or brands. It encompasses a wide range of activities, including search engine optimization (SEO), social media marketing, email marketing, content creation, and online advertising. The key objective of digital marketing is to connect with target audiences in the digital realm, engaging potential customers, and driving business growth. Digital marketing campaigns are often data-driven, allowing businesses to analyze and optimize their strategies based on real-time insights and metrics. This dynamic and interactive approach enables companies to reach a global audience, tailor messages to specific demographics, and measure the effectiveness of their marketing efforts with a level of precision not always possible in traditional marketing channels."
  },

  {
    question:"How long does digital marketing take to produce results?",

    answer:"The timeline for yielding results through digital marketing is variable, typically spanning from a few weeks to several months. The specific duration hinges on factors such as the chosen digital strategies, industry competitiveness, and the nature of the business. Channels like paid advertising may generate quicker outcomes, often within weeks, while organic strategies like search engine optimization (SEO) may require a more patient approach, taking several months for noticeable impact. Consistent efforts, data analysis, and strategic adjustments are essential in optimizing digital marketing campaigns for effectiveness and efficiency over time."
},

{
  question:"What are the ideal digital marketing channels? ",

  answer:"The ideal digital marketing channels for a business depend on its goals, target audience, and industry. A comprehensive approach often includes a mix of channels. Search engine optimization (SEO) is crucial for improving online visibility, while paid advertising on platforms like Google Ads and social media (Facebook, Instagram, LinkedIn) can efficiently target specific demographics. Email marketing remains a powerful tool for direct communication and nurturing customer relationships. Content marketing through blogs, videos, and social media contributes to brand authority and engagement. Additionally, businesses benefit from leveraging analytics tools to monitor and optimize performance across these channels, ensuring a well-rounded and effective digital marketing strategy."
},


{
  question:"What tools and analytics should be used to measure performance?",

  answer:"To measure the performance of digital marketing efforts, businesses can leverage a suite of tools and analytics platforms. Google Analytics is a fundamental tool for tracking website traffic, user behaviour, and conversion metrics. Social media platforms provide their own analytics features for monitoring engagement, reach, and audience demographics. Email marketing platforms such as Mailchimp or Constant Contact offer insights into email campaign performance. SEO tools like Moz or SEMrush help assess website visibility and keyword rankings. Additionally, customer relationship management (CRM) systems like Salesforce or HubSpot can provide a holistic view of customer interactions. Utilizing these tools collectively allows businesses to gather comprehensive data, assess the effectiveness of various channels, and make informed decisions to optimize their digital marketing strategies for better performance. All these can be done at Code Desk to make your digital experience easier."
},
  
{
  question:"What is a Marketing Funnel?",

  answer:"A marketing funnel is a conceptual framework that illustrates the stages a potential customer goes through before making a purchase. It represents the journey from initial awareness of a product or service to the final conversion. The funnel typically consists of stages like awareness, consideration, and decision. At the top of the funnel, businesses aim to attract a broad audience, creating awareness through various marketing channels. As prospects move down the funnel, the focus shifts to providing more detailed information and nurturing leads. The bottom of the funnel is where conversion occurs, and strategies concentrate on encouraging a purchase decision. The marketing funnel serves as a guide for businesses to tailor their marketing efforts at each stage, optimizing the customer journey and ultimately driving conversions and customer loyalty."
},


 ]




 export const sliderData  = [

     {
      name:"Chenny",
      proffession:"CEO of (LocumDesk)",
      message:"My experience working with the managers at Code Desk on my project, Locum Desk, has been fantastic. I truly appreciate their dedication and expertise.",
      src:testimonialImage1,
     },
     
    {
      name:"Chenny",
      proffession:"CEO of (Medfuture)",
      message:"Working with Code Desk has been an exceptional experience for our team at Medfuture.",
      src:testimonialImage3,
     },

    //  {
    //   name:"Chenny",
    //   proffession:"CEO of (Medfuture)",
    //   message:"My experience working with the Code Desk managers on my project, Locum Desk, has been awesome.Thank you.",
    //   src:testimonialImage1,
    //  },

    //  {
    //   name:"Chenny",
    //   proffession:"CEO of (Medfuture)",
    //   message:"My experience working with the Code Desk managers on my project, Locum Desk, has been awesome.Thank you.",
    //   src:testimonialImage3,
    //  },
    


 ]

 export const  models = [
  {
     id:1,
     src:t3,
     url:"/services/strategy-planning/t3-model"
  },

  {
    id:2,
    src:c2,
    url:"/services/branding-creative-design/c2-model"
 },

 {
  id:3,
  src:c3,
  url:"/services/coding-technology/c3-model"
 },

  {
   id:4,
   src:c4,
   url:"/services/digital-marketing/c4-model"
 },

 {
  id:5,
  src:c5,
  url:"/services/content-marketing/c5-model"
 },
 {
  id:6,
  src:c6,
  url:"/services/campaigns/c6-model"
 },
 {
  id:7,
  src:e5,
  url:'/corporate/e5-model'
 }
]



// Book and Text Part

export  const modelInfoE5 = {
     heading:"Your Complete Hub for Premier Digital Marketing Services!",
     info:"E5 by Code Desk: Your Ultimate Digital Symphony – Where Every Note of Digital Marketing Excellence Resonates!.",
     img:e5Img
  }

 export const modelInfoC2 = {

     heading:"C2 - Branding and Creative Design",
     info:"Step into a realm where the ordinary transforms into the extraordinary. At Code Desk, we are not just a marketing agency",
     img:c2Img,

  }

 export  const modelInfoC3 = {
     heading:"C3 - Coding and Technology ",
     info:"Harness the latest advancements in development and technology to deliver innovative solutions.",
     img:c3Img,
   
  }

 export  const modelInfoC4 = {
     heading:"C4 - Digital Marketing",
     info:"Digital marketing is the transformative catalyst that propels your brand into the digital spotlight!",
     img:c4Img,
  
  }

 export  const modelInfoC5 = {
     heading:"C5 - Content Marketing",
     info:"A masterpiece of storytelling that resonates, captivates, and leaves an everlasting imprint on your brand's journey to greatness",
     img:c5Img,

  }

 export  const modelInfoC6 = {
     heading:"C6 - Campaign",
     info:"Effective campaign planning at Code Desk is more than a strategy; it's a carefully choreographed roadmap that ensures every element aligns seamlessly.",
     img:c6Img,
 
  }
  
  export const modelInfoT3= {

     heading:"Your Complete Hub for Premier Digital Marketing Services!",

     info:"Pioneering success through strategic innovation, turning visions into actionable plans and redefining the future.",

     img:t3Image
  }



  // Help Content Branding Page
  export const Helpcontent = {
    image:helpImage,

    data:[

        "Code Desk specializes in expert web design, crafting strategic brand identities that not only align with your business goals but also resonate powerfully with your target audience.",

        "Our experienced team excels in branding, creating distinctive and memorable elements that encapsulate the very essence of your brand.",

        "Code Desk ensures an outstanding user experience through expert UI/UX design, optimizing your website for both impeccable functionality and captivating aesthetics.",

        "Working diligently, we establish a cohesive and impactful brand identity, incorporating visual elements, messaging, and an overall brand personality.",

        "Our dedicated team guides you through the entire brand creation process, ensuring a seamless and compelling narrative.",

        "Our services extend to custom web design, tailoring your online presence to reflect your brand identity and engaging your target audience."
    ]
  }





