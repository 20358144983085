import React from 'react'
import { ArrowStyled } from '../../styles/home.styles'
import { FaArrowRight } from 'react-icons/fa6'


const Arrow = () => {
    return <ArrowStyled>
      <FaArrowRight color='white' size={'15px'} />
    </ArrowStyled>
  }

export default Arrow