import React from "react";
import { ThemeProvider } from "styled-components";
const Theme = ({children})=>{

   const theme = {
      colors:{
         primaryGreen:'#50EA7B',
         primaryBlue:'#54BFBF',
         primaryWhite:'#FFF',
         primaryBlack:'#181717',
         primayDarkBlack:'#181313',
         primaryLighterBlack:'#292930',
         primaryGrey:'#6D6D77',
         primaryLightGrey:'#C3C3C3',
         primaryLighterGray:'#928B8B',
         primaryGreyLight:'#f4f4f4',
         primaryLigthestGray:'#F9F9F9',
         footerDark:'#060707'
      },
      sizes:{
          heading1:4.9,
          heading2:3.6,
          heading3:2.6,
          heading4:1.8,
          body:1.125,
      },
    
}

   return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
export default Theme;