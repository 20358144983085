// Variants Related to navigation
export const navContainer = {
    visible: {
      x: 0,
      opacity: 1,
      transition: {
  
        duration: 0.3
      }
    },
     hidden: {
      x: "100%",
      opacity: 0,
      transition: {
     
        duration: 0.3
      }
     },
    
     leave: {
      x: "100%",
      opacity: 0,
      transition: {
     
        duration: 0.5
      }

     }
  };


  export const navList = {
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.3
      }
    },
    hidden: {
      opacity: 0,
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1
      }
    },
    leave:{
       opacity:0,
       transition:{
         when:"afterChildren"
       }
    }
 
  };

  export const dropDownList = {
    visible: {
      opacity: 1,
      y:0,
      transition: {
        delayChildren: 0.1,
     
      }
    },
    hidden: {
      opacity: 0,
      y:10,
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1
      }
    },
    leave:{
       opacity:0,
    
    }
 
  };


  export const navItem = {
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    hidden: {
      x: 20,
      opacity: 0,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
   
  };
  

// Variants Related to Services Cards

 export const serviceVarientParent = {
    initial :{

       opacity:0,
       scaleY:0,

    },animate:{
      opacity:1,
       scaleY:1,
       transition:
       { duration: 0.75,ease:"easeOut",staggerChildren:-0.5,when:"beforeChildren" }
      
   
       
    }
}

  
export const serviceVarientChild = {
  initial :{

     opacity:0,
     scaleY:0

  },animate:{
    opacity:1,
     scaleY:1,
     transition:
      { duration: 0.75,ease:"easeOut",staggerChildren:-0.5,when:"beforeChildren" }
     
  }
}


