import React from "react";
import {
  Chip,
  FormControl,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";


import styled from "styled-components";
import { Andriod, DesktopMini, LG, TabVertical } from "../../styles/responsive";
import PrimaryButton from "./primaryButton";
import CImg from '../../assets/images/q-5.webp';


const FormSection = styled.div`
    padding: ${props=>props.mode? "0 20":'0'}em;
    background-image: url(${props=>props.mode? CImg:'none'});
    background-repeat: no-repeat;
    background-position: right;
    ${TabVertical({padding:"3em 2em"})}
    .m-number input{
      color:${props=>props.mode? "#f5f5f5":"black"} !important;
    }
    h3 {
      font-family: 'Satoshi', sans-serif;
      font-weight: 600;
      font-size: 2.5rem;
      margin-bottom: 0.5em;
     ${Andriod({fontSize:"1.5rem"})}
    }

 

    @media screen and (max-width:767px) {
      [data-form-contact]{
       top: -2em;
       padding: 2em 1.5em;

     } 

    }

    @media screen and (min-width:1600px) {
      padding: ${props=>props.mode? "0 0":'0'}em;
      max-width: 1000px;
      margin: 0 auto;


    }


    ${Andriod({  padding: "0 1.5em 2em 1.5em" })};


   
`

const CustomTextField = styled.input`
  width: 100%;
  background-color:${props=>props.mode? "rgba(255,255,255,0.2)": "white"};
  color:${props=>props.mode? "#f5f5f5":"black"};
 
  border: 0px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  outline: none;
  font-size: 14px !important;
  &::placeholder{
     color:${props=>props.mode? "#f5f5f5":"black"};
  }
  &:hover {
  }
`;

const budgetInputStyles = {
  ".my-budget-field": {
    "::-webkit-inner-spin-button": {
      appearance: "none",
      margin: 0,
    },
    "::-webkit-outer-spin-button": {
      appearance: "none",
      margin: 0,
    },
  },
};

function ContactForm({ handleFormClose,text,mode,txt,clr,outline,deSelecct,name,place }) {
  const [value, setValue] = React.useState("Individual");

  const [error, setError] = React.useState();

  const [firtstNameErr,setFirstNameErr] = React.useState("")
  const [lastNameErr,setLastNameErr] = React.useState("")
  const [emailErr,setEmailErr] = React.useState("")


  const [valueMobile, setValueMobile] = React.useState('');
  const [valueEmail, setValueEmail] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [marketingBudget, setMarketingBudget] = React.useState('');
  const [note, setNote] = React.useState('');
  const [companyname, setCompanyname] = React.useState('');
  const [url, setUrl] = React.useState('');

  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const [openDialog, setOpenDialog] = React.useState(false);







  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const validateEmail = (email) => {
    const regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    return regex.test(email);
  };

  const handleChangeEmail = (event) => {
    setValueEmail(event.target.value);
    if (!validateEmail(event.target.value)) {
      setError("Please enter a valid email address");
    } else {
      setError("");
      setValueEmail(event.target.value)
    }
  };



  const options1 = ["Social media Trace", "Ad Trace", "SEO Trace"];

  const options2 = [
    "Branding Book",
    "Mobile Apps",
    "Web Pages",
    "Corporate Communications",
  ];

  const options3 = [
    "Web Development",
    "Mobile App Development",
    "Landing Page Development",
  ];

  const options4 = [
    "Search Engine Marketing",
    "Ad Campaign Marketing",
    "Content Marketing",
    "Social Media Marketing",
  ];

  const handleChipClick = (option) => {
    const newOptions = [...selectedOptions];
    if (newOptions.includes(option)) {
      newOptions.splice(newOptions.indexOf(option), 1);
    } else {
      newOptions.push(option);
    }
    setSelectedOptions(newOptions);

  };

  const handleNumber = (value) =>{
       setValueMobile(value)
  }

  const handleClickOpenDialog = () => {
    
    const erros = [];
    setFirstNameErr('');
    setLastNameErr('');
    setEmailErr('')
    setError('')

    const formObject = {
        first_name:firstName,
        last_name:lastName,
        email:valueEmail,
        customer_mobile:valueMobile,
        marketing_budget:marketingBudget,
        note: note,
        company_name:companyname,
        webURl:url,
        strategic_and_planning: selectedOptions.filter((options,index)=>options==options1[index]),
        branding_and_creative_design: selectedOptions.filter((options,index)=>options==options2[index]),
        coding_and_technology_integration:selectedOptions.filter((options,index)=>options==options3[index]),
        digital_marketing: selectedOptions.filter((options,index)=>options==options4[index])
      

    }

    if(formObject.first_name==='') {
       setFirstNameErr("Required")
       erros.push("Error");
    }

    if(formObject.last_name==='') {
       setLastNameErr("Required")
       erros.push("Error");
    }

    if(formObject.email==='') {
      setEmailErr("Required")
      erros.push("Error");
   }

    console.log(erros);
    if(erros.length>0) {
       return 
    }

    //http://codedesk.shop/
    fetch('http://54.179.25.4/api/contact-email', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',

          
        // Add any additional headers if needed
      },
     
      body: JSON.stringify(formObject),
     })
         .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
      }).catch(err=>console.log(err))

      setOpenDialog(true);
  };

  const handleCloseDialog = () => {
 
    setOpenDialog(false);
    handleFormClose();
  
  };



  return (

     <FormSection data-form-body mode={mode} place={place}>
      <FormControl data-form-contact   sx={{ width: "100%" ,bgcolor:clr,padding:mode?"2em 7em":0,borderRadius:"2em",position:'relative',top:mode?"-5em":"0"}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{color:txt,textAlign:mode?"center":"",lineHeight:1.1,marginBottom:"2em"}}>{text}</Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <Stack direction="row">
                <FormControlLabel
                  value="Individual"
                  control={<Radio style={{ color: '#50EA7B' }} size="small" />}
                  label="Individual"
                />
                <FormControlLabel
                  value="Corporate"
                  control={<Radio style={{ color: '#50EA7B' }} size="small" />}
                  label="Corporate"
                />
              </Stack>
            </RadioGroup>
          </Grid> */}

          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} md={6}>
            <Typography sx={{ ml: 2 }} variant="body2" color="error">
         
                  {firtstNameErr}
                </Typography>
              <CustomTextField mode={mode} type="text" onChange={(e)=>setFirstName(e.target.value)} placeholder="First Name" />
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography sx={{ ml: 2 }} variant="body2" color="error">
                  {lastNameErr}
        
                </Typography>
              <CustomTextField mode={mode} type="text" onChange={(e)=>setLastName(e.target.value)} placeholder="Last Name" />
        
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPhoneNumber
                variant="outlined"
                className="m-number"
                onChange={handleNumber}
                
                sx={{
                  width: "100%",
                  backgroundColor:mode?"rgba(150, 150, 150,0.4)":"white",
                  borderRadius: `10`,
                  // padding:"13.5px 14px",
                  // height: 40,
                  
                 
                }}
                readOnly={false}
                defaultCountry="au"
                InputProps={{
                  sx: {
                    ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      color:'red'
                    },
                    "&:hover": {
                      ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}  md={6}>
              <Stack direction="row">
                <Typography sx={{ ml: 2 }} variant="body2" color="error">
                  {error && <span>{error}<br/></span>} 
                  {emailErr && <span><br/>{emailErr}</span>}
                </Typography>
              </Stack>
              <CustomTextField mode={mode}
                onChange={handleChangeEmail}
                type="text"
                placeholder="Email"
              />
            </Grid>

          </Grid>
          <Grid item xs={12}  md={6}>
            <CustomTextField mode={mode} onChange={(e)=>setCompanyname(e.target.value)} type="text" placeholder="Company Name" />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField mode={mode} onChange={(e)=>setUrl(e.target.value)} type="text" placeholder="Web URL" />
          </Grid>

          {/* =====================Service Select==================== */}
          <Grid item container xs={12}>
            <Grid item sx={{mt:2, color:mode?"rgba(255,255,255,0.6)":"#222",}} xs={12}>How Can We Help You?</Grid>
            <Grid item xs={12} md={6} sx={{ mt: 2 }}>
              <Typography sx={{color:mode?"rgba(255,255,255,0.6)":"#222"}} variant="body1">Strategy and Planning</Typography>
              <div>
                {options1.map((option, index) => (
                  <Chip
                    key={option}
                    label={option}
                    selected={selectedOptions.includes(option)}
                    onClick={() => handleChipClick(option)}
                    variant={
                      selectedOptions.includes(option) ? "filled" : "outlined"
                    }
                    style={{
                      marginRight: 10,
                      marginTop: 10,
                      borderColor: "#50EA7B",
                      background: selectedOptions.includes(option)
                        ? "linear-gradient(to right, #50EA7B, #54BFBF)"
                        : null,
                        color: selectedOptions.includes(option) ? "#fff" : deSelecct,
                    }}
                  />
                ))}
              </div>
            </Grid>

            <Grid item xs={12} md={6} sx={{ mt: 2 }}>
              <Typography sx={{color:mode?"rgba(255,255,255,0.6)":"#222"}} variant="body1">
                Branding & Creative Design
              </Typography>
              <div>
                {options2.map((option, index) => (
                  <Chip
                    key={option}
                    label={option}
                    selected={selectedOptions.includes(option)}
                    onClick={() => handleChipClick(option)}
                    variant={
                      selectedOptions.includes(option) ? "filled" : "outlined"
                    }
                    style={{
                      marginRight: 10,
                      marginTop: 10,
                      borderColor: "#50EA7B",
                      background: selectedOptions.includes(option)
                        ? "linear-gradient(to right, #50EA7B, #54BFBF)"
                        : null,
                      color: selectedOptions.includes(option) ? "#fff" : deSelecct,
                    }}
                  />
                ))}
              </div>
            </Grid>

            <Grid item xs={12} md={6} sx={{ mt: 2 }}>
              <Typography sx={{color:mode?"rgba(255,255,255,0.6)":"#222"}} variant="body1">
                Coding & Technology Integration
              </Typography>
              <div>
                {options3.map((option, index) => (
                  <Chip
                    key={option}
                    label={option}
                    selected={selectedOptions.includes(option)}
                    onClick={() => handleChipClick(option)}
                    variant={
                      selectedOptions.includes(option) ? "filled" : "outlined"
                    }
                    style={{
                      marginRight: 10,
                      marginTop: 10,
                      borderColor: "#50EA7B",
                      background: selectedOptions.includes(option)
                        ? "linear-gradient(to right, #50EA7B, #54BFBF)"
                        : null,
                        color: selectedOptions.includes(option) ? "#fff" : deSelecct
                    }}
                  />
                ))}
              </div>
            </Grid>

            <Grid item xs={12} md={6} sx={{ mt: 2 }}>
              <Typography sx={{color:mode?"rgba(255,255,255,0.6)":"#222"}}  variant="body1">Digital Marketing</Typography>
              <div>
                {options4.map((option, index) => (
                  <Chip
                    key={option}
                    label={option}
                    selected={selectedOptions.includes(option)}
                    onClick={() => handleChipClick(option)}
                    variant={
                      selectedOptions.includes(option) ? "filled" : "outlined"
                    }
                    style={{
                      marginRight: 10,
                      marginTop: 10,
                      borderColor: "#50EA7B",
                      background: selectedOptions.includes(option)
                        ? "linear-gradient(to right, #50EA7B, #54BFBF)"
                        : null,
                        color: selectedOptions.includes(option) ? "#fff" : deSelecct
                    }}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
          {/* =====================End Service Select==================== */}

          <Grid item xs={12} md={6} sx={{ mt: 2 }}>
            <Typography sx={{color:mode?"rgba(255,255,255,0.6)":"#222"}}  variant="body1">Marketing Budget</Typography>
            <CustomTextField mode={mode} onChange={(e)=>setMarketingBudget(e.target.value)} type="nmber" placeholder="$0.00" />
          </Grid>

          <Grid item xs={12} md={6} sx={{ mt: 2 }}>
            <Typography sx={{color:mode?"rgba(255,255,255,0.6)":"#222"}} variant="body1">Note</Typography>
            <CustomTextField mode={mode} onChange={(e)=>setNote(e.target.value)} type="text" placeholder="Note" />
          </Grid>
          
          <Grid item xs={12} sx={{ mt: 2 }} display="flex" justifyContent={mode?"center":"start"}>
            <PrimaryButton
              onClick={handleClickOpenDialog}
              text="Get In Touch"
            ></PrimaryButton>
          </Grid>
        </Grid>
      </FormControl>
      {openDialog && <Dialog open={openDialog} keepMounted onClose={handleCloseDialog}>
        
        
        <DialogContent
          sx={{
            padding: "20px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <DialogContentText>
            One of our experts will get in touch with you soon 
          </DialogContentText>
        </DialogContent>

        <DialogActions
          
        inputprops={{
            sx: {
              ".MuiDialogActions-custom-dialog-actions": {
                display: "flex",
                justifyContent: "center",
              },
            },
          }}
        >
          <PrimaryButton text="Ok"  onClick={handleCloseDialog}></PrimaryButton>
        </DialogActions>
      </Dialog>}
    </FormSection>


  );
}

export default ContactForm;
