import React from 'react'
import {  SubHeading } from '../../styles/home.styles'
import styled from 'styled-components'
import { ContainerCentered, Content } from '../../styles/common.styles';



import MergedImg from '../../assets/images/merged.webp'
import MergedImgPlaceholder from '../../assets/images/placeholders/merged.png'
import { Andriod, LG } from '../../styles/responsive';
import { LazyLoadImage } from 'react-lazy-load-image-component';



const WhoWeAreSection = styled.section`
  padding: 3em 0;

  ${Andriod({padding:"2em 0"})}
`;
const FlexBreak = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5em;
  &>div{
     width :100% ;
  }
  ${Andriod({flexDirection:"column",padding:"0 0.5em",gap:"0.5em"})}

`;
const OuterImgLayer = styled.div`
  position: relative;
  /* transition: clip-path 250ms ease-in;
  clip-path: circle(0rem at 50% 50%);
  &:hover {
   clip-path: circle(40rem at 50% 50%);

  } */
`;
const WhoWeImage = styled(LazyLoadImage)`
   max-width: 30rem;
   width: 100%;
   ${Andriod({margin:"0 auto"})}
   
`;

const ContentLayer = styled.div`
     h2{
         text-align: left;
    }
      p {
         line-height :1.8 ;
         margin-top:2em;
         color:#000;
         font-size: clamp(0.9rem,1.2vw,1.5rem);
         ${Andriod({fontSize:"1rem",marginTop:"1em"})}
         ${LG({marginTop:"1em"})}
         &:last-child {
          ${LG({marginTop:"2em"})}
         }
          
         
     }
`;

const WhoWeAre = () => {



  return (
     <WhoWeAreSection>
      
           <ContainerCentered>
            <FlexBreak>
                
                       <OuterImgLayer data-aos="fade-right" data-aos-easing='ease-out-cubic' data-aos-duration='1000'  data-aos-offeset="300" >
                  
                        <WhoWeImage  effect='blur'  placeholderSrc={MergedImgPlaceholder}  sizes='(min-width:1023px)780px, 100vw'  srcSet={MergedImg}    src={MergedImg} alt='Image'/>

                    

                       </OuterImgLayer>  
                 

                <ContentLayer  data-aos="fade-up" data-aos-easing='ease-in' data-aos-duration='500' >
                    
                        <SubHeading>Who We Are</SubHeading>
                        <Content>

                        Welcome to The Code Desk where data meets strategy to fuel brand success. We're more than a marketing agency; we're dedicated partners in navigating the dynamic marketing landscape with a team of passionate marketing professionals. 
                        </Content>
                        <Content>
                               Our dedicated team, comprised of professional content writers, web developers, 
                               graphic designers, and SEO experts and more, craft unique, 
                               data-driven approaches to amplify your brand, captivate your audience, 
                               and deliver measurable results. Embark on a journey where your vision 
                              becomes our mission, turning client success into an ingrained habit.                       
                       </Content>
        
                </ContentLayer>
            </FlexBreak>
        </ContainerCentered>   
     </WhoWeAreSection>
  )
}

export default WhoWeAre