import React from 'react'
import styled from 'styled-components';
import { Container } from '../../styles/common.styles';
import gaurd from '../../assets/images/gaurd.webp'
// import CImg from '../../assets/images/q-5.webp';
import GImg from '../../assets/images/GImg.webp'
import { Andriod, LG, TabVertical } from '../../styles/responsive';

const GaraunteeSection = styled.section`
   padding: 7em 7em 2em 7em;
   position: relative;
   ${TabVertical({padding:"2em 2em"})}
   ${Andriod({padding:"4em 0.5em 2em 0.5em"})};
   
   &::before{
     position: absolute;
     content: '';
     left: 0;
     bottom: 0;
     width: 100%;
     height: 40%;
     background-color:#F5F5F5;
   }
   ${LG({padding:"7em 14em 2em 14em"})}

`;
const GaraunteeImage = styled.img`
   display: block;
   width: 220px;
   height: auto;
   position: absolute;
   top: -100px;
   /* transform: translate(-50%, 0%); */
   left: 20%;
   right: 20%;
   margin: 0 auto;
   ${Andriod({top:"-75px",left:"85px",right:"85px",transform:"translate(0)",width:"125px",margin:"0 auto"})};
 
`;
const GaraunteeBox = styled.div`
  background-color: #fff;
  position: relative;
  text-align: center;
  padding: 2em 2em 3em 2em;
  -webkit-box-shadow: 16px 21px 23px -28px rgba(0,0,0,0.75);
  -moz-box-shadow: 16px 21px 23px -28px rgba(0,0,0,0.75);
  box-shadow: 16px 21px 23px -28px rgba(0,0,0,0.75);
  border: 1px solid #f5f5f5;
  border-radius: 0.5em;
  background-image: url(${GImg});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
  ${TabVertical({padding:"0 2em"})}
  ${Andriod({padding:"1.5em 1em 2em 1em"})};
  ${LG({paddingBottom:"5em",paddingTop:"3em"})}
   



`;
const GaraunteeText = styled.h3`
    font-family: 'Satoshi', sans-serif;
   font-size: clamp(1.2rem,3vw,${({theme})=>theme.sizes.heading3}rem);
   font-weight: 600;
   padding:1em 2em 0 2em;
   line-height: 1.1;
   margin-bottom: 0.75em;
   ${Andriod({padding:"1em 1em 0 1em"})};
   ${LG({padding:"1em 5em 0 5em"})}



`;
const GaraunteeContent = styled.p`
  padding: 0 8em;
  font-weight: 300;
  font-size: 1rem;
  ${Andriod({padding:"0em 1em",fontSize:"0.9rem"})};
  ${LG({ fontSize: "1.2rem"})}

`

const Garauntee = () => {
  
  return (
    
      <GaraunteeSection>
         <Container>
              <GaraunteeBox>
                    <GaraunteeImage data-aos-offset={400} width={"240"} height={"100"} loading='lazy'  sizes='(min-width:1023px)780px, 100vw' srcSet={gaurd} data-aos="flip-left" data-aos-easing='ease-out-cubic' data-aos-duration='1000'  alt='Gaurd' src={gaurd}/>

                    <GaraunteeText data-aos-offset={400} data-aos-delay="200" data-aos='fade-up'   data-aos-duration='1000'  >Over 100 businesses have found success by partnering with Code Desk.</GaraunteeText>

                    <GaraunteeContent data-aos-offset={400} data-aos='fade-up'   data-aos-delay="400" data-aos-duration='1000'>
                    Navigating the future with precision, 'We Drive based on Data' harnesses the power of information to revolutionize the driving experience. Our approach leverages data-driven insights to enhance safety, optimize efficiency,
                    </GaraunteeContent>

              </GaraunteeBox>
         </Container>
      </GaraunteeSection>
  )
}

export default Garauntee