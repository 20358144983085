import React, {  useRef } from 'react'
import { StatisticStyled } from '../styles/home.styles'
import {styled} from 'styled-components'

import { useCountUp }  from 'react-countup';




const Flex = styled.div`display:flex; align-items:flex-end; gap:1em;`
const FlexMiddle = styled.div`display:flex; align-items:center; gap:1em;`
const Img = styled.img` width:35px;`;


const Figures = styled.h3`
   font-size: ${({theme})=>theme.sizes.heading4}rem;
   font-weight: 600;
   margin: 0;
`;
const Text = styled.span`
     font-size: 0.9rem;
     margin: 0;
     font-weight: 500;
`;
const Col = styled.div`

`;

const Stat = ({figure,src,sign,text}) => {

  const ref = useRef(null); 
  const statsRef = useRef(null); 


  useCountUp({ ref:ref, end: figure ,start:0,enableScrollSpy:true,scrollSpyOnce:true,scrollSpyDelay:1000});



  return (
      <StatisticStyled ref={statsRef} >
 
             <FlexMiddle>
               {/* <h3 ref={ref}></h3> */}
                  {src}
                  <Col>
                      <Figures   ref={ref}><span>{figure}</span>{sign}</Figures>
                      <Text>{text}</Text>
                  </Col>
             </FlexMiddle>
      </StatisticStyled>
  )
}

export default Stat