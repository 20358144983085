import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components';
import { Andriod, LG } from '../../styles/responsive';

const BreadCrumbsStyled = styled.div`
   display: flex;
   position: absolute;
   gap:0px;
   top:6em;
   z-index: 5;
   justify-content: flex-start;
   width: min(calc(100% - 5vw), 1320px);
   left: 50%;
   transform: translateX(-50%);

  
  
   & > *{
  display: inline-block;
  margin-right: 10px;
   }
  & .crumb a:after {
    content: '-';
    margin-left: -5px;
  }
  .crumb:last-child a:last-child:after {
    display: none;
    content: none;
  }
  & .crumb a {
    color: #ddd;
  }

   a {
     text-decoration: none;
     color:#fff;
     text-transform: capitalize;
     display: flex;
     gap: 1em;
     position: relative;
     align-items: center;
     transition: all 250ms ease-in;
     ${Andriod({ fontSize: "0.8rem" })}
     &:hover {
        color:#50EA7B
     }
     /* &::before {
       position: absolute;
       content: '';
       right: -10px;
       height: 1px;
       width: 8px;
       background-color: #fff;
     } */
  
   }
   ${Andriod({ paddingLeft: "10px", top: "5em" })}
   ${LG({ width: "min(calc(100% - 5vw), 1600px)" })}
`

const BreadCrumbs = () => {
  const location = useLocation();
  // console.log(location)
  let currentLink = '';
  let crumbs;
  crumbs = location.pathname.split('/').filter((item) => item !== '')
    .map((crumb, index) => {
      currentLink += `/${crumb}`;

      console.log(currentLink);
      return <div className='crumb  ' key={crumb}>
        <Link style={{ pointerEvents: index === 0 ? 'none' : 'auto' }} to={currentLink}>{crumb} </Link>
      </div>
    });

  return (
    <BreadCrumbsStyled>
      {crumbs}
    </BreadCrumbsStyled>
  )
}

export default BreadCrumbs