import React from 'react'
import { Captions, TestimonailProfile, TestimonialContainer, TestimonialName, TestimonialQuote,Slide,
     TestimonialStyled,TestimonailImage, SubHeading} from '../../styles/home.styles'
     import { ImQuotesRight } from "react-icons/im";
import Slider from "react-slick";
import { sliderData } from '../../data/data';
import styled from 'styled-components';
import { Andriod } from '../../styles/responsive';
import Reveal from '../../components/transitions/revel'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Flex = styled.div`
    display: flex;
    gap: 100px;
    align-items: center;
    &>:first-child{
         width: 350px;
         ${Andriod({width:"100%"})}
    }
    ${Andriod({gap:"20px",flexDirection:"column"})}

`

const Testimonials= () => {

    const settings = {

        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        // variableWidth: true,
        arrows:true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1
              }
            },
          ]
       
      };


  return (
    <TestimonialStyled>
        <Reveal>
           <SubHeading>What our Clients Say</SubHeading>
        </Reveal>
        
        <Reveal>
        <TestimonialContainer>      
                <Slider {...settings}>
                {sliderData.map((slide,index)=>{
                        return <Slide key={index}>
                                <Flex>
                                    <TestimonailImage  height="200" width="200" sizes='(min-width:1023px)780px, 100vw' srcSet={slide.src}  loading="lazy" src={slide.src} alt={"Review Image"} /> 
                                    <TestimonailProfile>
                                            <ImQuotesRight  color='#CAC8BB'/>
                                            <TestimonialQuote>
                                                {slide.message}    
                                            </TestimonialQuote> 
                                                
                                        <Captions>
                                            <TestimonialName>
                                               
                                                {slide.proffession}
                                            </TestimonialName>
                                            {/* <TestimonailProfession>
                                                {slide.name}
                                            </TestimonailProfession> */}
                                        </Captions>
                                    </TestimonailProfile>  
                            </Flex>
                            </Slide>  
                    })}

                </Slider>
        </TestimonialContainer>
    </Reveal>
   </TestimonialStyled>

  )
}

export default Testimonials