import React from 'react'
import { CTA, InnerLayer } from '../../styles/common.styles'

const OutlinedButton = ({click,text}) => {
  return (
    <CTA onClick={click}>          
        <InnerLayer>
             {text}
        </InnerLayer>
    </CTA>
  )
}

export default OutlinedButton