import React from 'react'
import styled from 'styled-components'

const ButtonStyled  =  styled.button`

    background-image: linear-gradient(to right, ${props => props.theme.colors.primaryGreen}, ${props => props.theme.colors.primaryBlue});
    border-radius: 2px;
    border: 0;
    border:2px solid transparent;
    color:${({theme})=>theme.colors.primaryWhite};
    padding: 0.75em 2em;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: transform 350ms ease-in,opacity 450ms cubic-bezier(.76,.05,.86,.06);
    &:focus {
       outline: none;
    }
    &::before,&::after {
      position: absolute;
      content: '';
      inset:0;
    }

    &:hover {
       border-color: #fff;;
    }

   &::before {
     height: 100%;
     background-color:rgba(0,0,0,0.9);
     z-index: -1;
     transform-origin: left;
     transform: scale(0,1);
     border-radius: 3px;
     transition: transform 450ms ease-in , opacity 450ms ease-in;
   }
   &:hover::before {
     transform: scale(1,1);
     
   }
  




`

const PrimaryButton = ({text,onClick,onMouseMove,onMouseLeave}) => {
  return (
      <ButtonStyled onMouseMove={onMouseMove&&onMouseMove} onMouseLeave={onMouseLeave && onMouseLeave} onClick={onClick} >
              {text} 
      </ButtonStyled>
  )
}

export default PrimaryButton