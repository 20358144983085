import React from "react";
import {
  Heading,
  HomeContainer,
  HomeStyled,
  Text,
} from "../../styles/home.styles";
import PrimaryButton from "../ui/primaryButton";

import { useForm } from "../../context/formContext";

const BannerSection = ({ heading, subtext, hasForm, bgImg, caption,name }) => {

  // const [isFormOpen, setFormOpen] = React.useState(false);
  // const [isHome,setIsHome] = useState();

  const {handleFormOpen} = useForm();


  // const url = useLocation().pathname.split('/');
  // useEffect(()=>{
  //   console.log("use Effect run this is the url",url[url.length - 1]);
  //   if(!url[url.length - 1]){
  //      setIsHome(false)
  //   }else {
  //      setIsHome(true)
  //   }
  // },[url])


  return (
    <>

      <HomeStyled bg={bgImg} id='intro' className={name && name}>
        <HomeContainer>
          {caption && ( <Text >{caption}</Text>)}
          {heading && <Heading>{heading}</Heading>}

          {subtext && ( <Text >{subtext}</Text>

          )}

          {hasForm && (
            <div>
              <PrimaryButton
                height={'52px'}
                onClick={handleFormOpen}
                text={"Get In Touch"}
              />
            </div>

          )}
        </HomeContainer>
      </HomeStyled>

    </>
  );
};

export default BannerSection;
