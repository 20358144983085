import React from 'react'
import { FooterContainer, FooterStyled } from '../../styles/common.styles'

import styled from 'styled-components'
import { Link } from 'react-router-dom'

// Icons
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsTelephoneFill } from "react-icons/bs";

// Images
import FooterLogoImg from '../../assets/images/other/new-2.webp';
import { Andriod } from '../../styles/responsive';




// Inbuilt styles
// const QuickLinks = styled(Link)`
//   text-decoration: none;
//   color:${({ theme }) => theme.colors.primaryWhite};
//   ${Andriod({ marginTop: "0em" })};
// `
import './index.css'
const Row = styled.div`
  display: flex;
  justify-content: ${props => props.justify ? "center" : "space-between"};
  padding-top: ${props => props.spacing ? 5 : 0}em;
  margin-bottom: ${props => props.spacing ? 3 : 0}em;
  gap: 1em;
  color: ${({ theme }) => theme.colors.primaryWhite};
  ${Andriod({
   flexDirection: "column", gap: "2em", paddingTop: "0em", justifyContent: "flex-Start",
   marginTop: (props) => (props.spacing ? '2em' : '0')
})};
`;

const FooterLogo = styled(Link)`
  &:hover {
    opacity: 0.8;
  }
`;

const FooterImg = styled.img`
  /* width: 140px; */
`

const FooterSocialMedia = styled.div`
     display: flex;
     align-items:flex-start;
     gap: 1em;
     ${Andriod({ alignItems: "flexStart" })};

    
    
`;

const SocialIcon = styled(Link)`
   color: ${({ theme }) => theme.colors.primaryWhite};
   display: inline-block;
   padding: 1.5em 1.5em;
   border: 1px solid ${({ theme }) => theme.primaryLighterGray} ;
   border-radius: 50%;
   display: flex;
   transition: all 250ms ease-in;
   ${Andriod({ padding: "1em" })};
   &:hover {
       color: ${({ theme }) => theme.colors.primaryGreen};
   }
`;

const Line = styled.div`
   height: 2px;
   width: 100%;
   margin: 0 auto;
   color:${({ theme }) => theme.colors.primaryLighterGray};
   margin-bottom: 2em;
   ${Andriod({ display: "none" })};
`;

const P = styled.p`
    text-align: center;
    color:${({ theme }) => theme.colors.primaryLighterGray};

    ${Andriod({ textAlign: "left" })};
`;

const FooterContact = styled.div`
  width: 40%;
  ${Row}{
    justify-content: space-between;
    gap:4em;
    ${Andriod({gap:"1em",width:"100%"})}
    
  }
  ${Andriod({width:"100%"})}

`;
const FooterTitle = styled.h3`
  font-family: 'Satoshi',sans-serif;
  font-size: 1.5rem;
  margin-top: 0;
`;
const FooterAddress = styled.p`
line-height: 1.5;
font-weight: 300;
`
const FooterNumber = styled.p`
  a{
   text-decoration: none;
   color:#fff;
   &:hover {
       opacity: 0.8;
   }
  }
`

const Footer = () => {
   return (
      <FooterStyled >
         <FooterContainer data-aos='fade-up'  data-aos-duration='500' data-aos-easing='ease-in'>
            <Row >
               <FooterLogo  to='/' title="Code Desk | Home Page">
                  <FooterImg  loading='lazy' height={"50"} width={"120"} alt='Footer Logo'  sizes='(min-width:1023px)780px, 100vw'  srcSet={FooterLogoImg}  src={FooterLogoImg} />
               </FooterLogo>

               <FooterContact>
                  <FooterTitle>Contact Information</FooterTitle>
                  <Row>
                   <FooterAddress>
                    
                    <FaMapMarkerAlt />
                    Code Desk <br />
                    Ground floor <br />
                    470 St Kilda Rpad <br />
                    Melbourne VIC 3004 <br />
                    Australia
                    </FooterAddress>

                   <FooterAddress>
                     <FaMapMarkerAlt />
                       The Code Desk <br />
                       No 65C , 7th Floor <br />
                       City Bank Building <br />
                       Dharmapala Mawatha  <br />
                       Colombo 7 <br />
                       Sri Lanka
                   </FooterAddress>

                  </Row>
                    <FooterNumber>
                    <BsTelephoneFill/> <Link to='tel:1800990880' title='Click to call'>1800 990 880</Link>
                    </FooterNumber>
               </FooterContact>

               <FooterSocialMedia>
                  <SocialIcon title="Click to visit" to='https://www.linkedin.com/company/the-code-desk/'>
                     <FaLinkedinIn size={20} />
                  </SocialIcon>

                  <SocialIcon title="Click to visit" to='https://www.facebook.com/profile.php?id=61553530307199'>
                     <FaFacebookF size={20} />
                  </SocialIcon>

                  <SocialIcon title="Click to visit" to='https://www.instagram.com/the_code_desk/'>
                     <FaInstagram size={20} />
                  </SocialIcon>

                  <SocialIcon title="Click to visit" to='https://www.youtube.com/@TheCodedesk'>
                     <FaYoutube size={20} />
                  </SocialIcon>
               </FooterSocialMedia>
            </Row>

            {/* <Flex justify="center" spacing="top">
               <QuickLinks >
                     Support
                </QuickLinks>


                <QuickLinks >
                     Privacy policy
                </QuickLinks>
                 
                <QuickLinks >
                     Terms and Conditions
                </QuickLinks>  
            </Flex> */}
            <Line />
            <P>
               © 2024 Code Desk. All Rights Reserved
            </P>
         </FooterContainer>
      </FooterStyled>
   )

}
export default Footer
