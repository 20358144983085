import React, { useState } from 'react'
import { Container, DropDown, DropDownItem, DropDownParent, HeaderStyled, List, ListItem, LogoImage, Navigation, NavigationLink, TogglerButton } from '../../styles/common.styles'

// Images   
import Logo from '../../assets/images/other/new-2.webp'
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useRef } from 'react';

import { motion, AnimatePresence } from 'framer-motion'
import { AiFillCaretDown } from "react-icons/ai";

import OutlinedButton from '../ui/outlinedButton';


import { Drawer, IconButton, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import ContactForm from '../ui/contactForm'
import { FaBars } from 'react-icons/fa6';
import { AiOutlineClose } from "react-icons/ai";
import { useForm } from '../../context/formContext';
import { dropDownList, navContainer, navItem, navList } from '../../data/variants';
import BreadCrumbs from '../common/breadcrumbs';
import MobileHeading from '../common/mobileHeading'

// import './index.css'


const NavigateMotion = motion(Navigation);
const ListItemMotion = motion(ListItem);
const ListMotion = motion(List)

const DropDownMotion = motion(DropDown);
const DropDownItemMotion = motion(DropDownItem);
const DropDownLinkMotion = motion(NavigationLink)

const Header = () => {

  const headerRef = useRef();
  const dropDowmRef = useRef();
  const path = useLocation()


  const [isToggled, setToggle] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const { isFormOpen, handleFormClose, handleFormOpen } = useForm()

  const toggleDropDown = () => {
    setDropDownOpen(!dropDownOpen);
  }


  const menuItems = [
    {
      name: "Strategic & Planning",
      path: '/services/strategy-planning'
    },
    {
      name: "Digital Marketing",
      path: '/services/digital-marketing'
    },
    {
      name: "Content Marketing",
      path: '/services/content-marketing'
    },
    {
      name: "Coding & Technology",
      path: '/services/coding-technology'
    },
    {
      name: "Branding & Creative Design",
      path: '/services/branding-creative-design'
    },
    {
      name: "Campaigns",
      path: '/services/campaigns'
    },

  ]

  useEffect(() => {


    document.body.addEventListener('click', (e) => {
      if (!e.target.parentElement) {
        return
      }
      if (!(dropDowmRef.current === (e.target.parentElement) || dropDowmRef.current === (e.target.parentElement.parentElement))) {

        setDropDownOpen(false)
      }

    })


  }, [])


  useEffect(() => {
    if (window.innerWidth <= 767) {
      setToggle(false)
    }
  }, [path.pathname])


  useEffect(() => {
    if (window.innerWidth > 767) {
      setToggle(true)

    }
    const checkSize = () => {

      if (window.innerWidth > 767) {
        setToggle(true)
      }
      else { setToggle(false) }

    }

    window.addEventListener('resize', checkSize)
    return () => window.removeEventListener('resize', checkSize)

  }, [])

  useEffect(() => {
    const changeHeaderClr = () => {

      if (headerRef.current) {

        if (window.scrollY > headerRef.current.offsetHeight - 60) {
          headerRef.current.style.background = "black"
          // headerRef.current.style.padding="0.8em 0px"  
        } else {
          headerRef.current.style.background = "transparent"
          // headerRef.current.style.padding="0.8em 0px"
        }
      }
    }

    window.addEventListener('scroll', changeHeaderClr)
    return () => window.removeEventListener('scroll', changeHeaderClr)

  }, [])

  return (
    <>
      <HeaderStyled ref={headerRef}>
        <Container>
          <Link to={'/'} title='Code Desk | Home Page'>
            <LogoImage  height={"40"} width={"120"} sizes='(min-width:1023px)780px, 100vw' srcSet={Logo} src={Logo} alt='Code Desk' />

          </Link>

          <AnimatePresence onExitComplete={() => console.log("Exit")}>
            {isToggled &&
              <NavigateMotion variants={navContainer} animate={isToggled ? "visible" : "hidden"} exit="leave" initial='hidden' >

                <ListMotion initial="hidden" animate="visible" exit={"hidden"} variants={navList} >

                  <ListItemMotion ref={dropDowmRef} variants={navItem}  >
                    <DropDownParent className={dropDownOpen ? "active" : ""}
                      onClick={window.innerWidth <= 767 ? toggleDropDown : null}
                      onMouseLeave={window.innerWidth > 767 ? () => setDropDownOpen(false) : null}
                      onMouseEnter={window.innerWidth > 767 ? () => setDropDownOpen(true) : null}>
                      Services
                      <AiFillCaretDown />

                      <AnimatePresence>
                        {dropDownOpen && <DropDownMotion initial="hidden" animate="visible" exit={"hidden"} variants={dropDownList} >
                          {menuItems.map((item, index) => {
                            return <DropDownItemMotion key={index} variants={navItem}  >
                              <DropDownLinkMotion to={item.path}>
                                {item.name}
                              </DropDownLinkMotion>

                            </DropDownItemMotion>
                          })}
                        </DropDownMotion>
                        }
                      </AnimatePresence>

                    </DropDownParent>


                  </ListItemMotion>

                  <ListItemMotion variants={navItem}  >
                    <NavigationLink to={'/corporate/e5-model'}>Corporate</NavigationLink>
                  </ListItemMotion>


                  <ListItemMotion variants={navItem}  >
                    <NavigationLink to={'/contact'}>Contact</NavigationLink>
                  </ListItemMotion>

                  <ListItemMotion variants={navItem} marginleft={"auto"}>
                    <OutlinedButton click={handleFormOpen} text={"Book a free Call"} />
                  </ListItemMotion>
                </ListMotion>
              </NavigateMotion>
            }
          </AnimatePresence>

          <TogglerButton type="button" onClick={() => setToggle(!isToggled)}>{isToggled ? <AiOutlineClose color='#fff' size={"36px"} /> : <FaBars color='#fff' size={"30px"} />}</TogglerButton>
        </Container>

        {/* =============== Form Drawer=========== */}
        <Drawer anchor="right" open={isFormOpen} onClose={handleFormClose}>
          <Box
            sx={{ width: 600, overflowX: "hidden", p: 4, backgroundColor: "#F8F8FF", }}
          >
            <ContactForm place="header" name="name" outline="#ccc" deSelecct={"#222"} text={"Get In Touch"} handleFormClose={handleFormClose} />
            <IconButton
              onClick={handleFormClose}
              sx={{ position: "absolute", top: 8, right: 8 }}
            >
              <CloseIcon sx={{ fontSize: 40 }} />
            </IconButton>
          </Box>
        </Drawer>
        {/* =============== End Form Drawer=========== */}
      </HeaderStyled>
      <BreadCrumbs />
      <MobileHeading />
    </>

  )
}

export default Header
