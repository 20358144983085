import React  from 'react'
import { SerivceImage, Service,  ServiceContentLayer, ServiceImageLayer, ServiceNavigation, ServiceTitle } from '../../styles/home.styles'
import { motion,  useMotionValue, useSpring, useTransform } from 'framer-motion'
import Arrow from '../../components/ui/arrow';



const ServiceItem = ({ service }) => {


  const ServiceImageMotion = motion(ServiceImageLayer);

  /*TILT*/
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const mouseXSpring = useSpring(x);
  const mouseYSpring = useSpring(y);

  const rotateX = useTransform(
    mouseYSpring,
    [-0.5, 0.5],
    ["10.5deg", "-10.5deg"]
  );
  const rotateY = useTransform(
    mouseXSpring,
    [-0.5, 0.5],
    ["-10.5deg", "10.5deg"]
  );

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const xPct = mouseX / width - 0.5;
    const yPct = mouseY / height - 0.5;

    x.set(xPct);
    y.set(yPct);
  };

  const handleMouseLeave = () => {
    x.set(0);
    y.set(0);
  };
  /*TILT*/

  return (
    <Service 
      data-aos={service.id>3?"fade-left":"fade-right"}
      data-aos-duration="1000"
      data-aos-easing='ease-in-out'
      data-aos-delay={300 * (service.id * 0.1)}
      className={service.id === 2 ? "main" : ''}

      key={service.id} spacing={service.spacing ? "nm" : ""}
      middle={service.middle ? "middle" : ""} >


      <ServiceContentLayer
   >
        <ServiceNavigation to={service.url} title='Click to visit' main={service.id === 2 ? "main" : ''} onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave} down={service.down ? "down" : ""}>Desk <Arrow /> </ServiceNavigation>
        <ServiceTitle    dangerouslySetInnerHTML={{ __html: service.text }} />
      </ServiceContentLayer>

      <ServiceImageMotion   down={service.down ? "down" : ""} 
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{
          rotateY,
          rotateX,
          transformStyle: "preserve-3d",
        }}>

        <SerivceImage  sizes='(min-width:1023px)780px, 100vw'  srcSet={service.src}   style={{
          transform: "translateZ(75px)",
          transformStyle: "preserve-3d",
          
        }}  src={service.src} alt={service.text} />
      </ServiceImageMotion>

    </Service>
  )
}

export default ServiceItem



// Previous Verson //
/*


  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const mouseXSpring = useSpring(x);
  const mouseYSpring = useSpring(y);

  const rotateX = useTransform(
    mouseYSpring,
    [-0.5, 0.5],
    ["10.5deg", "-10.5deg"]
  );
  const rotateY = useTransform(
    mouseXSpring,
    [-0.5, 0.5],
    ["-10.5deg", "10.5deg"]
  );

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const xPct = mouseX / width - 0.5;
    const yPct = mouseY / height - 0.5;

    x.set(xPct);
    y.set(yPct);
  };

  const handleMouseLeave = () => {
    x.set(0);
    y.set(0);
  };
  

  return (
    <ServiceMotion ref={ref}
      variants={serviceVarientChild} 
      className={service.id===2?"main":''}  
      initial={"initial"} animate={animate}  
      key={service.id} spacing={service.spacing?"nm":""} 
      middle={service.middle ? "middle" : ""} >


      <ServiceContentLayerMotion variants={servicContentVarient}   >
        <ServiceNavigation to={service.url} title='Click to visit' main={service.id===2 ? "main":''}   onMouseMove={handleMouseMove}
                            onMouseLeave={handleMouseLeave} down={service.down ? "down" : ""}>Desk <Arrow /> </ServiceNavigation>
        <ServiceTitle dangerouslySetInnerHTML={{ __html: service.text }} />
      </ServiceContentLayerMotion>

      <ServiceImageMotion  donw={service.down ? "down" : ""} 
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{
          rotateY,
          rotateX,
          transformStyle: "preserve-3d",
        }}>

        <SerivceImage  sizes='(min-width:1023px)780px, 100vw'  srcSet={service.src}  style={{
          transform: "translateZ(75px)",
          transformStyle: "preserve-3d",
        }}  src={service.src} alt={service.text} />
      </ServiceImageMotion>

    </ServiceMotion>
  )
}

export default ServiceItem

*/
