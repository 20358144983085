import { motion } from "framer-motion";
import {styled} from 'styled-components'

const  SlideIn = styled.div`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, #50EA7B, #54BFBF); 
      transform-origin:left;
      z-index: 999;
     
`
// const  SlideOut= styled.div`
//        position: fixed;
//        top: 0;
//        left: 0;
//        width: 105%;
//        height: 100%;
//        background: linear-gradient(to right,  #50EA7B,#54BFBF); 
 
//        transform-origin: left;
//          z-index:999;  
// `

const Transition = (OgComponent) =>{
   //  Make Styled Components Motion Variables


    const SlideInMotion = motion(SlideIn);
   //  const SlideOutMotion = motion(SlideOut);


   
   return ()=> (
            <>  
              <OgComponent />
              <SlideInMotion  
               
                 initial={{translateX:1}}
                 animate={{scaleX:0}}
                 exit={{scaleX:1.1}}
                 transition={{duration:0.75,ease:"easeIn"}}
              />
                  

            </>
     )
}

export default Transition
 