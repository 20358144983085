import React, { useState } from 'react'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { IoClose } from "react-icons/io5";
import { CiMenuFries } from "react-icons/ci";
import styled from 'styled-components';

const Close= styled.div`
 display: flex;
 justify-content: flex-end;
 position: absolute;
 right: 0px;
 top: -10px;
 cursor: pointer;
 /* top:-5px; */
 background-color: #fff;
 border-radius: 25px;
`

const WidgetIcon = () => {
  //const [close,setClose] = useState(false)

  return (
           <>
             {/* <Close onClick={()=>setClose(!close)}>
                 {close ?<CiMenuFries fontSize={"20px"} color='#50EA7B' /> :  <IoClose fontSize={"20px"} color="#ea5050"/> }
             </Close> */}
                {[{icon:<FaFacebookF/>,link:"https://www.facebook.com/profile.php?id=61553530307199"}, 
                  {icon:<FaLinkedinIn/>,link:"https://www.linkedin.com/company/the-code-desk/"},
                  {icon:<FaInstagram/>,link:"https://www.instagram.com/the_code_desk/"}, 
                  {icon:<FaYoutube/>,link:"https://www.youtube.com/@TheCodedesk"}].map((icon,number)=>{
                    return  <Link  data-aos='zoom-in' data-aos-delay={number*100}  to={icon.link} title='Click to visit' key={number}>
                            {icon.icon}
                        </Link>
                })}
            </>
  )
}

export default WidgetIcon