import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
// import bg from '../../assets/images/home-banner-2.webp'
const DynamicMetaComponent = ({ pageTitle, pageDescription, img }) => {
   //https://cd-seo.vercel.app${location.pathname}

   const location = useLocation();
   return (
      <HelmetProvider>
         <Helmet>
            <meta data-rh="true" name="description" content={pageDescription} />
            <link data-rh="true" rel="canonical" href={`https://thecodedesk.com${location.pathname}`} />
            <meta data-rh="true" property="og:locale" content="en_us" />
            <meta data-rh="true" property="og:type" content="website" />
            <meta data-rh="true" property="og:title" content={pageTitle} />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:image" content={img} />
            <meta data-rh="true" property="og:image:width" content="1200" />
            <meta data-rh="true" property="og:image:height" content="630" />
            <meta data-rh="true" property="og:url" content={`https://thecodedesk.com${location.pathname}`} />
            <meta data-rh="true" property="og:site name" content={"Code Desk"} />
          
            <title>{pageTitle}</title>

         </Helmet>
      </HelmetProvider>



   );
};

export default DynamicMetaComponent;