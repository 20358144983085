import React from 'react'
import ContactForm from '../../components/ui/contactForm'

const ContactFormSection = () => {

  return (
    <section data-aos='fade-up' data-aos-duration="1000" >
           <ContactForm  clr="black" deSelecct={"#F5F5F5"}  txt="white" mode="dark" text={<span> Ready to Elevate <br/> Your Brand? </span> }/>
    </section>
  )
}

export default ContactFormSection