import React, { useContext, useEffect, useState } from 'react'

const FormContext = React.createContext();

export function useForm(){
     return useContext(FormContext);
}


 
const FormProvider = ({children}) => {

  const [isFormOpen, setFormOpen] = useState(false);
  const [name,setName] = useState('')
  const [mounted,setMount] = useState(true);


  const handleFormOpen = (name) => {
    setName(name)
    setFormOpen(true);
    setMount(false)
  };

  const handleFormClose = () => {
  
   
    setFormOpen(false);
   
  };

 
  const animateOnce = ()=>{
       setMount(true)
  }

  useEffect(()=>{
    console.log('fire');
    document.body.style.overflowY="none";
  },[isFormOpen])



  const formProps ={
       isFormOpen,handleFormClose,handleFormOpen,name,mounted,animateOnce
  }
  return (
   <FormContext.Provider value={formProps}>
       {children}
   </FormContext.Provider>
  )
}

export default FormProvider