import React from 'react'
import {  services2 } from '../../data/data'
import {  ServiceContainer,ServicesStyled, ServicsSection,  SubHeading, SubHeadingContent } from '../../styles/home.styles'
import Reveal from '../../components/transitions/revel'
import ServiceItem from './service';



const Services = () => {

  return (
    <ServicsSection>
       <ServiceContainer >
             <Reveal>
             <SubHeading >What We Offer</SubHeading>
             </Reveal>


              <SubHeadingContent data-aos='fade-up' data-aos-duration='1000'  >
                 We specialize in SEO, Social Media Marketing, Strategic Content, and PPC Marketing services and more.
                  Our expert team crafts tailored solutions to optimize your online presence, boost search engine rankings, and drive targeted traffic 
                  through strategic content creation and targeted PPC campaigns.
              </SubHeadingContent>

              <ServicesStyled   >     
                    {
                    services2 &&  services2.map((service)=>{
                            return  <ServiceItem key={service.id}  service={service}  />
                      })
                    }
              </ServicesStyled>
        </ServiceContainer>
     </ServicsSection>
  )
}

export default React.memo(Services)



/*

  <ServiceMotion
                            
                            onMouseMove={handleMouseMove}
                            onMouseLeave={handleMouseLeave}
                            style={{
                              rotateY,
                              rotateX,
                              transformStyle: "preserve-3d",
                            }}   variants={serviceVarient}  initial={{

                              opacity:0,
                              translateX:index % 2 === 0 ? 50: -50,
                              translateY:'-50',
                     
                           }} animate={animate}  transition={{duration:0.3,delay:index * 0.2}}to={`/${service.text}`} key={service.id} down={service.down ?"down":""} >
                           
              
                              <ServiceContentLayer>

                                 <ServiceNavigation down={service.down ?"down":""}>Desk <Arrow/> </ServiceNavigation>
                                 <ServiceTitle dangerouslySetInnerHTML={{__html:service.text}}/>

                                </ServiceContentLayer>
                        
                                 

                                 <ServiceImageLayer>
                                        <SerivceImage src={service.src} alt={service.text} />
                                 </ServiceImageLayer>
                            </ServiceMotion>
*/ 