import React, { useEffect, useRef } from 'react'
import {motion,useInView,useAnimation} from 'framer-motion'
const Reveal = ({children}) => {

    const ref = useRef(null);
    const inview  = useInView(ref,{once:true,amount:'some'});
    const animate = useAnimation()
    const slide = useAnimation()
 
    useEffect(()=>{

 
      if(inview){
            animate.start("visible")
            slide.start('visible')
          
      }
     },[inview])

     const varients =  {
      hidden:
      {
       opacity:0,
       y:75
     
     },
     
      visible:
       { opacity:1,
          y:0
       
      },
      
      
     }

  return (
     <div style={{position:'relative',width:"100%"}} ref={ref}>
          <motion.div  
          
          variants={varients}

          initial="hidden"
          animate={animate}
          transition={{
           duration:0.5,
           delay:0.25,
          }}>
                {children}
          </motion.div>
     </div>
  )
}

export default Reveal