import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const MobileHeading = () => {

    const location = useLocation().pathname.split('/')
    const [showStatus, setShowStatus] = useState();
    const isHome = location.filter((item)=>item!=='');
 
    useEffect(() => {


        function checkRoutes(){

          
            console.log(isHome);

                if ((isHome.length && window.innerWidth >=767 )|| (isHome.length && window.innerWidth <=767)){
                    //console.log("this is not the home ",location[location.length - 1]);
                    setShowStatus(false)
                    return 
                }
                else if((!isHome.length && window.innerWidth <=767) || (!isHome.length && window.innerWidth >=767)) {
                    //console.log("this  the home ",location[location.length - 1]);
                    setShowStatus(true)
                    return 
                
                }
        }
        checkRoutes();

    }, [location])


    const handleResize = () => {

        if((!location[location.length - 1]  ) && (window.innerWidth <= 767)) {

            setShowStatus(true);
        }
        else if((location[location.length - 1])  && (window.innerWidth <= 767)) {
            setShowStatus(false)
        }

    };

    useEffect(() => {
    
        window.addEventListener('resize', handleResize);
        // Cleanup function to remove the event listener when component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <><AnimatePresence>
            {showStatus && <motion.h1 exit={{ opacity: 0}}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1}}
            transition={{ duration: 0.2,delay:0.5,ease:"easeOut" }}
            onAnimationComplete={() => console.log("exit")} className='show-mob'>Ready to scale your revenue  and  build a strong brand?</motion.h1> }

        </AnimatePresence></>
    )
}

export default MobileHeading