import {styled} from 'styled-components'
import { Link ,NavLink} from 'react-router-dom';
import { Andriod, LG, TabVertical } from './responsive';
import { RowBasic, SubHeading } from './home.styles';



export const HeaderStyled  = styled.header`
 background-color: transparent; 
 padding: 0.6em 0;
 position: fixed;
 z-index: 22;
 width: 100%;
 transition:all 250ms ease-in;

 ${Andriod({padding:"0.8em 10px"})}

`
export const Container = styled.div`    
  width: min( calc(100% - 5vw) , 1320px );
  margin:0 auto ;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${LG({ width: "min(calc(100% - 5vw), 1600px)"})}


`
export const ContainerBasic = styled.div`
     width: min( calc(100% - 5vw) , 1320px );
     margin:0 auto ;
     ${LG({ width: "min(calc(100% - 5vw), 1600px)"})}

`
export const ContainerNm = styled(Container)`

justify-content: center;

`

export const Navigation = styled.nav`
  width :65% ;
  font-family: 'Satoshi',sans-serif;
  position:relative;

  &::before{
   content:"";
   ${Andriod(
    {  
       position:'absolute',
       top:0,
       left:"-50%",
       padding:"4em 2em",
       background:'rgba(0,0,0,0.4)',
       width:"80%",
       height:'100vh',   
    }
   )
   }
  };
  
  ${TabVertical({width: "75%"})}

  ${Andriod(
    {  display:"block",
       position:'absolute',
       top:0,
       right:0,
       padding:"5em 2em",
       background:'#000',
       height:"100vh",
      //  overflow:"hidden",
       width:"75%"
     
    }
   )
   };
`
export const List = styled.ul`
   display:flex;
   list-style: none;
   margin: 0;
   gap:4em;
   align-items:center;
   justify-content: flex-end;


   ${TabVertical({paddingLeft: "0"})}

   
   ${Andriod(
   {flexDirection:"column",alignItems:"start",paddingLeft:"0"}
   )
   };

 `

export const ListItem = styled.li`
  margin-left:0;
  position: relative;
  ${Andriod({marginLeft:0,marginTop:(props) => props.marginleft ? "1em" : 0})};
  a {
     font-size:1rem;
     /*  ${({theme})=>theme.sizes.body}rem */
     text-decoration: none;
     &::before,&::after {
         ${Andriod( {content:"none"}  )  
      };

     }
     ${Andriod( {border:0,padding:0,fontSize:"1.1rem"} ) };
     ${LG({ fontSize: "1.2rem"})}


  }
`

export const TogglerButton = styled.button`
      display:none;
      cursor: pointer;
      border: 0;
      background-color: transparent;
       ${Andriod({display:"block",postion:'relative',zIndex:999})};

`
export const DropDown = styled.ul`
  position: absolute;
  background-color: black;
  list-style: none;
  top: 2em;
  padding: 1em 1.5em;
  min-width: 250px;
  border-radius: 0.5em;
  ${Andriod({position:"static",minWidth:"340px",borderRadius:0,paddingTop:"1em"})}
  ${LG({ minWidth: "300px"})}


`;
 export const DropDownItem = styled.li`
   margin-bottom: 1em;
   
    a{ 
      ${Andriod({fontSize:"0.9rem"})}
    }
   &:nth-of-type(4) {
      ${Andriod({marginBottom:0})}

   }
 `;
export const DropDownLink = styled(Link)``;

export const NavigationLink = styled(NavLink)`
     color: ${({theme})=>theme.colors.primaryWhite};
     position: relative;
   
     transition: all 350ms ease-in;
     &.active::before{
      transform: scale(1,1);
     }
       
   
     &::before{
        position :absolute ;
        content: '';
        width: 100%;
        top: 1.3em;
        height: 3px;
        border-radius: 3px;
        background-image: linear-gradient(to right, ${({theme}) => theme.colors.primaryGreen}, ${({theme}) =>theme.colors.primaryBlue});
        transform: scale(0,1);
        transform-origin: center;
        transition: all 350ms ease-in;
      
     }

     &:hover{
      opacity: 0.8;
         &::before{
            transform: scale(1,1);
         }
        
    }
`

export const  DropDownParent = styled.span`
   cursor: pointer;
   display: flex;
   align-items: center;
   color: ${({theme})=>theme.colors.primaryWhite};
     position: relative;
     /* transition: all 150ms ease-in; */
     &.active::before{
      transform: scale(1,1);
     }
       
   
     &::before{
        position :absolute ;
        content: '';
        width: 100%;
        top: 1.3em;
        height: 3px;
        border-radius: 3px;
        background-image: linear-gradient(to right, ${({theme}) => theme.colors.primaryGreen}, ${({theme}) =>theme.colors.primaryBlue});
        transform: scale(0,1);
        transform-origin: center;
        transition: all 150ms ease-in;
      
     }

     &:hover{
    
         &::before{
            transform: scale(1,1);
         }
        
    }
    ${Andriod({display:"inline"})}
    ${LG({ fontSize: "1.2rem"})}


`

export const  LogoImage = styled.img`
   max-width: 120px;
   width: 100%;
   ${Andriod({height:"40px"})};
`;

export const OuterLayer = styled.span`
`;


export const InnerLayer = styled.span`
        background-image: linear-gradient(to right, ${({theme}) => theme.colors.primaryGreen}, ${({theme}) =>theme.colors.primaryBlue});
      color:transparent;
      background-clip:text;
      -webkit-background-clip: text;
      transition: all 550ms ease-in;
`;

export const Space = styled.div`
    padding: 1em 0;
    /* ${Andriod({padding:"1em 0"})} */

`

export const Summary = styled.p`
   font-size: 1.2rem;
   font-weight: 500;
   margin: 0;
   margin-top: 0.5em;
   font-family: 'Satoshi',sans-serif;
   line-height: 1.4;
   font-weight: 600;
   ${Andriod({fontSize:"1rem",margin:"1em 0"})}
`;
export const Revision = styled(Summary)`
     font-size:1rem;
     font-family: unset;
     display: flex;
     align-items: center;
     gap: 0.5em;
`

export const CTA  = styled.button`
    border:0;
    cursor: pointer;
    display:inline-block;
    position: relative;
    padding:0.6em 1.3em;
    background:rgba(0,0,0,0.1);
    border-radius:3px;
    text-transform:capitalize;
    overflow: hidden;
    border: 1px solid  ${({theme}) => theme.colors.primaryGreen};
    transition: all 350ms ease-in;
    text-decoration: none;
    &:hover ${InnerLayer} {
      color:#fff;
    }

    &:after {
      content: "";
       position: absolute;
       inset:0;
       margin: -5px;
       background-image: linear-gradient(to right, ${({theme}) => theme.colors.primaryGreen}, ${({theme}) =>theme.colors.primaryBlue});
       transform: scale(0,1);
       transform-origin:left;
       z-index: -1;
       border-radius: 3px;
       transition: transform 350ms ease-in,opacity 350ms ease-in;
       
    }
    &:hover::after{
       transform: scale(1,1);
       opacity: 1;
    }

`
export const ContentRight= styled.p`
  line-height: 1.7;
  font-size: 1.1rem;
  font-weight: 300;
  ${LG({fontSize:"1.3rem"})}
`;


// Services
export const InnerLayerServiceStyled = styled.section`
   background-color:#F7F7F7;
   padding: 3em 0;
`

export const InnerServiceSection = styled.section`
  padding: 1.5em 0 13em 0;
  ${Andriod({padding:"0em 0.6em 1em 0.6em"})}
`

export const Content = styled.p`
  color:#000000;
  font-size: clamp(0.9rem,1.25vw,1.5rem);
  font-weight: 300;
`;

export const CenteredContentStyled = styled.div`
     padding:2em 5em 2em 5em;
     text-align: center;
     /* margin-bottom: 2em; */
    
     ${Andriod({padding:"2em 0 1em 0em"})}


`


export const ContainerCentered = styled.div`    
  width: min( calc(100% - 5vw) , 1320px );
  margin:0 auto ;
  ${LG({ width: "min(calc(100% - 5vw), 1600px)"})}



`


export const ContainerCenteredFlex= styled(Container)`
  display: flex;
  align-items: stretch;
`
export const ContainerCenteredFlexAround= styled(ContainerCenteredFlex)`
  justify-content: space-around;
  gap: 2em;
  ${Andriod({flexDirection:"column"})}
`

export const CenteredTitleDark = styled(SubHeading)`
     text-align: center;
     padding: 0 8em ;
     margin-bottom: 1em;
     line-height: 1.1;
     ${Andriod({padding:0})}

`
export const EvenColumns = styled.div`
    width: 45%;
    margin-top: 1em;
   ${Andriod({width:"100%",marginTop:"1em"})};
 
`
export const RowSpace = styled(RowBasic)`
  gap:5%;
  align-items: center;
`;

export const EvenColumnsInverse = styled(EvenColumns)`
    order: -1;
    margin-top: 4em;
    margin-bottom: 0em;
    ${Andriod({order:1,marginTop:"1em"})};

`
export const HeadingLeft = styled.h3`
    font-size: clamp(1.5rem, 2.5vw, ${({ theme }) => theme.sizes.heading3}rem);
    text-align: left;
    margin: 0;
    font-family: 'Satoshi', sans-serif;
    font-weight: 600;
`

export const HeadingLeftSm = styled(HeadingLeft)`
    font-size: 1.1rem;
    font-weight: 800;
`
export const ContentLeft= styled(Content)`
   font-size: 1rem;
   font-weight: 300;
   text-align: left;
   ${LG({ fontSize: "1.2rem"})}


`

export const FooterStyled = styled.footer`
    background-color:${({theme})=>theme.colors.footerDark};
    padding: 4em 2.3em 2em 2.3em;
    margin-top: auto;
    ${Andriod({ padding:"3em 1.2em 3em 1.2em"})};
`;

export const FooterContainer = styled.div`
    width: min(100%,1320px);
    margin: 0 auto;
    ${LG({ width: "min(calc(100% - 5vw), 1600px)"})}

`;

