import { BrowserRouter as Router } from 'react-router-dom'
import RouterContext from './routes/routerContext'

// import fonts from './styles/fonts.css'
// import MobileHeading from './components/common/mobileHeading'


function App() {

    return (

        <Router>
            {/* <MobileHeading /> */}
            <RouterContext />
        </Router>
    )
}

export default App
