import React from 'react'
import { ContainerCentered } from '../../styles/common.styles'
import { IntroTitle, Statistics } from '../../styles/home.styles'
import styled from 'styled-components'
import Stat from '../stat'
import { FaClipboardList, FaRegThumbsUp } from 'react-icons/fa6'
import { GiCog } from 'react-icons/gi'

const Flex = styled.div`
      display: flex;
      flex-direction: column;
      align-items:center;
`

const StatBar = () => {

  const stastics = [
    { id:0,
      figure:95,
      sign:'%',
      src:<FaRegThumbsUp color='#50EA7B' size={"40px"}/>,
      text:'Client Satisfaction'
    },
    { id:1,
     figure:14,
     sign:'+',
     src:<FaClipboardList color='#50EA7B' size={"40px"}/>,
     text:'Complete Projects'
   },
   { id:2,
     figure:1800,
     sign:'+',
     src:<GiCog color='#50EA7B' size={"40px"}/>,
     text:'Design Resource'
   }
 ]
  return (
    
    <ContainerCentered>
        <Flex>
     
            <IntroTitle>Our Progress</IntroTitle>
       

        <Statistics>
            {stastics && stastics.map((stastics)=>{
            return  <Stat key={stastics.id} {...stastics}/>        
            })}
        </Statistics>
        </Flex>
   </ContainerCentered>
  )
}

export default StatBar