import { styled } from "styled-components";
import { Link } from "react-router-dom";
import { Andriod, LG, TabVertical } from "./responsive";
import { LazyLoadImage } from "react-lazy-load-image-component";


export const HomeStyled = styled.section`
  &.home {
     padding-top: 19em;
     background-image: url('/bg/home-banner-2.webp');
     ${Andriod({paddingTop:"10em"})};
     @media screen and (max-width:386px) {
        padding-top: 12em;
     }
  }
  padding-top: 10em;
  padding-bottom:7em;
  /* min-height: 680px; */
  /* display: flex;
  align-items:center; */
  overflow: hidden;
  height: 80vh;
  position: relative;


  background-repeat: no-repeat;
  background-size: cover;
  background-attachment:fixed;
  background-blend-mode:multiply;
  display: flex;
  background-image: url(${(props) => (props.bg)});
  background-color: rgba(0,0,0,0.6);
  align-items: center;


  
  &.contact {
    padding:14em 0 10em 0;
    ${Andriod({padding:"7.5em .6em 3em .6em"})};

  }
  ${Andriod({height:"auto",padding:"7.5em 0.6em 2.5em 0.6em",backgroundAttachment:"scroll",backgroundPosition:"center"})};
`;

export const HomeContainer = styled.div`
  width: min(calc(100% - 5vw), 1320px);
  margin: 0 auto;
  padding-right: 10%;
  ${Andriod({paddingRight:0})};
  ${LG({ width: "min(calc(100% - 5vw), 1600px)"})}

`;

export const Heading = styled.h1`
  font-size: clamp(2rem, 4.2vw, ${({ theme }) => theme.sizes.heading1}rem);
  color: ${({ theme }) => theme.colors.primaryWhite};
  font-weight: 500;
  line-height: 1.1;
  /* padding-right: 5%; */
  letter-spacing: 1px;
  font-family: 'Satoshi', sans-serif;
  margin-top: 0;
  ${Andriod({paddingRight:0,letterSpacing:'-1px'})}
  ${LG({paddingRight:"1%"})}
`;



export const Text = styled.p`
  color: ${({ theme }) => theme.colors.primaryWhite};
  font-size: clamp(1rem, 1.5vw, ${({ theme }) => theme.sizes.body}rem);
  font-weight: 300;
  padding-right: 50%;
  opacity: 0.8;
  line-height: 1.7;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  ${Andriod({paddingRight:0,marginBottom:"1em"})};
`;

export const Review = styled.div`
  display: flex;
  gap: 1em;
  margin-top: 1em;
  padding-right: 60%;
  align-items: center;
  margin-bottom: 1em;
  ${Andriod({paddingRight:0,marginTop:"1em"})}
`;

export const ReviewText = styled.p`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primaryWhite};
  opacity: 0.8;
`;

export const ReviewSection = styled.div``;

export const Image = styled.img`
   ${Andriod({display:"none"})};
`;

export const Form = styled.form`
  display: flex;
  margin: 3em 0;
  gap: 1em;
`;

export const Input = styled.input`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1em 5em 1em 1em;
  border: 0;
  color: ${({ theme }) => theme.colors.primaryWhite};
  outline: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 2px;
`;

export const Stars = styled.div`
  display: flex;
  gap: 0.5em;
`;

// Sponser Seciton

export const SponserStyled = styled.div`
  display: flex;
  justify-content: flex-end;
   background-color: ${({ theme }) => theme.colors.primaryWhite};
   max-width: 1230px;
   margin: 0 auto;

`;

export const Sponser = styled.div`
  display: flex;
  padding: 2em 6em 0em 6em;
  flex-wrap: wrap;
  align-items: center;
  gap: 6em;
  row-gap: 2em;
  width: 100%;
  justify-content: center;
  width: min(calc(100% - 5vw), 1320px);
  margin: 1em auto;
  ${Andriod({display:"none"})};
  ${LG({ width: "min(calc(100% - 5vw), 1600px)"})}
`;

export const SponserLink = styled(Link)`
  ${Andriod({flexShrink:2})}
`;

export const SponserText = styled.h3`
  font-size: 0.9rem;
  font-weight: 300;
  color: #92949c;
  margin: 0;
  ${Andriod({fontSize:"0.7rem",flexShrink:1,width:"100%"})};

`;
export const SponserImg = styled.img`
  max-width: 100px;
  ${Andriod({maxWidth:"50px"})};
`;

// Staitstics
export const IntroStyled = styled.section`
  padding-bottom:${(props) => (props.space ? 3 :3)}em;
  padding-top: ${(props) => (props.space ? 3 : 3)}em;
  position: relative;
  ${Andriod({padding:"2em 0.6em"})}
`;

export const IntroTitle = styled.h2`
  text-align: center;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.primaryGreen},
    ${({ theme }) => theme.colors.primaryBlue}
  );
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 1.5rem;
  font-weight: 300;
  margin-top: 0em;
  margin-bottom: 1em;
  ${Andriod({margin:" 0 0em 0.5em 0"})}
`;

export const IntroTitleLeft = styled(IntroTitle)`
  text-align: left;
  margin-top: 1em;
  margin-bottom: 1em;
  ${Andriod({lineHeight:"1.2"})};

`

export const IntroContent = styled.p`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size:clamp(${({ theme }) => theme.sizes.body}rem, 1.5vw , ${({ theme }) => theme.sizes.heading4}rem) ;
  font-weight: 300;
  line-height: 1.5;
  margin-top: 1em;
`;

export const Colored = styled.span`
  background-image: linear-gradient(to right, #50EA7B, #54BFBF);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-weight:400 ;
`

export const Statistics = styled.div`
  display: flex;
  gap: 1em;
  margin-top: 0em;
  justify-content: center;
  width: 100%;
  font-family: 'Satoshi',sans-serif;
  ${Andriod({flexDirection:"column",alignItems:"center",marginTop:0})};
`;

export const StatisticStyled = styled.div`
  padding: 2em 1em;
  max-width: 16em;
  width: 100%;
  flex-grow: 1;
  transition: all 350ms ease-in;
  color:  ${({ theme }) => theme.colors.primaryGreen};
  border: 1px solid  ${({ theme }) => theme.colors.primaryGreen};
  background:#fff ;

  &:hover {
    color:  #fff;
    background:linear-gradient(to right, #50EA7B, #54BFBF) ;
    svg {
      transition: all 350ms ease-in;
       color:#fff !important;
    }
  }
`;

export const WidgetContainer = styled.div`
  position: absolute;
  right: 0px;
`;

export const CommingSoonContainer = styled(WidgetContainer)`
  left: 0px;
  right: auto;
`;

export const Widget = styled.div`
  position: ${(props) => (props.scrolled === "fixed" ? "fixed" : "relative")};
  top: ${(props) => (props.scrolled ? "150" : "0")}px;
  right: 0;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  background-color: #fff;
  box-shadow: 3px 1px 5px 0px rgba(217, 217, 217, 0.9);
  padding: 0em 0;
  z-index: 20;
  gap: 10px;
  svg {
    transition: all 250ms ease-in;
  }
  a {
 
    color: #50ea7b;
    padding: 0.75em 1.1em;
    font-size: 1.2rem;
    background-color: #fff;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    display: flex;
    right: 0;
    &:hover  svg {
      transform: translateX(-10px);
    }
  }
`;

export const CommingSoonBody = styled.div`
    position: ${(props) => (props.scrolled === "fixed" ? "fixed" : "sticky")};
    top: ${(props) => (props.scrolled ? "150" : "0")}px;
    left: -1px;
    z-index: 20;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 3px 1px 5px 0px rgba(217, 217, 217, 0.9);
    background-color: rgba(255, 255, 255 ,1);
    padding:  1em 1em 1em 0.1em;
    transition: transform 350ms ease-in 500ms;
    transform-origin: left;
    transform: scale(${(props) => (props.status ? "0" : "1")},1);

    svg {
       position: absolute;
       right: 20px;
       top: 5px;
       cursor: pointer;
    }
    p{ 
       transition:opacity 250ms ease-in ;
       opacity: ${(props) => (props.status ? "0" : "1")};
    }


`

// Services Section

export const ServicsSection = styled.section`
  padding: 1px 0em 5em 0em;
  position: relative;
  ${Andriod({padding:"2em 0.5em"})};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.primaryLigthestGray};

  &::before {
    position: absolute;
    content: "";
    height: 40%;
    width: 100%;
    left: 0;
    background-color: ${({ theme }) => theme.colors.primaryLigthestGray};
    z-index: -1;
  }
`;

export const ServiceContainer = styled.div`
  width: min(calc(100% - 5vw), 1320px);
  margin: 0 auto;
  padding-top: 3em;
  ${Andriod({paddingTop:"0em"})}
  ${LG({ width: "min(calc(100% - 5vw), 1600px)"})}

`;
export const SmallHeading = styled.h4`
  text-align: center;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.primaryGreen},
    ${({ theme }) => theme.colors.primaryBlue}
  );
  color: transparent;
  font-weight: 300;
  margin-bottom: 0;
  background-clip: text;
  -webkit-background-clip: text;
  ${LG({ width: "min(calc(100% - 5vw), 1600px)"})}

`;
export const SubHeading = styled.h2`
  font-size: clamp(1.5rem, 2.5vw, ${({ theme }) => theme.sizes.heading3}rem);
  text-align: center;
  margin: 0;
  font-family: 'Satoshi', sans-serif;
  font-weight: 600; 
`;

export const SubHeadingBold = styled(SubHeading)`
  font-weight: 800;
  font-size: clamp(1.5rem, 3.5vw, ${({ theme }) => theme.sizes.heading2}rem);
  line-height: 1.2;
  text-align: left;
  padding: 0;
`;


export const SubHeadingContent = styled.p`
    padding: 0 20%;
   text-align: center;
   color:#625959;
   font-size: clamp(0.9rem,1.2vw,1.5rem);
   font-weight: 300;
   ${Andriod({padding:"0 0"})};
   ${LG({padding:"0 10%"})}
`;

export const ServiceTitle = styled.h3`
  font-weight: 500;
  font-size: clamp(2rem, 2vw, ${({ theme }) => theme.sizes.heading3}rem);
  font-family: 'Satoshi',sans-serif;
  text-transform: capitalize;
  line-height: 1.3;
  font-weight: 600;
  color:#454040;
     -webkit-text-stroke: 1px var(--fill-color);
  background: linear-gradient(to right, #fff 100%, #fff 100%) right / 0 no-repeat;
  background-clip: text;
  margin: 0;
  transition: all 750ms ease-in;
  transition-delay: 100ms;

  &::before {
 
     position: absolute;
     content: '';
     inset:0;
     margin: -5px;
     background-image: linear-gradient(to right, rgb(80, 234, 123), rgb(84, 191, 191));
     z-index: -1;
     transform-origin: right;
     transform:scale(0,1);
     transition: all 400ms ease-in;
    transition-delay: 300ms;
  }

`;

export const SerivceImage = styled(LazyLoadImage)`

  object-fit: cover;
  flex-grow: 1;
  height: 100%;
  transition: all 1000ms ease-in;
  ${Andriod({maxHeight:"150px"})}

`;

export const Service = styled.div`
  position: relative;
  text-decoration: none;
  width:${(props)=>(props.middle ? "calc(40% - 1em)" : "calc(30% - 1em)")};
  min-height:${(props)=>(props.middle ? "375px" : "auto")};
  margin-top:${(props)=>(props.spacing ? "2em" : "0")};
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  border-radius: 0.3em;
  height: 100%;
  overflow: hidden;
  transform-origin: top;
  background-color:${({theme})=>theme.colors.primaryWhite};
  box-shadow: 16px 21px 23px -28px rgba(0,0,0,0.75);
  &.main{
      background: linear-gradient(to right,rgb(80, 234, 123),rgb(84, 191, 191)) ;
      h3 {
        color:#ffffff;
        -webkit-text-stroke: 1px var(--fill-color);
        background: linear-gradient(to right, #454040 100%, #454040 100%) right / 0 no-repeat;
       background-clip: text;
       font-size: 2.6rem;
       &::before {
               background-image: linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255));
       }
      }
  }
  ${Andriod({width:"100%",flexDirection:"column",minHeight:"300px"})};

`;
export const ServicesStyled = styled.div`
  display: flex;
  gap: 3em 1em;
  margin-top: 3em;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  align-content: baseline;
  ${Andriod({flexDirection:"column",overFlow:"hidden",marginTop:"2em"})}
`;

export const ServiceContentLayer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:1em;
  padding: 1em 0;
  flex-grow: 1;
  ${Andriod({alignItems:"flex-start"})}
  ${LG({padding:"1em 0 5em 0"})}

`;
export const ServiceImageLayer = styled.div`
  
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${TabVertical({width:(props=>props.down? "100%" : "50%")})}
  ${Andriod({width:"100%"})}

`;
export const ServiceNavigation = styled(Link)`
  order: 2;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 20px;
  color:#222;
  font-weight: 300;
  font-size:${({theme})=>theme.sizes.body}rem;
  position: relative;
  text-decoration: none;
  padding: 0.1em 0.1em 0.1em 1em;
  background: linear-gradient(white, white) padding-box,
              linear-gradient(to right,rgb(80, 234, 123),rgb(84, 191, 191)) border-box;
  border-radius: 30px;
  border: 1px solid transparent;
  transition: all 550ms ease-in;

  @keyframes orbit1 {
    0% { transform: translate(0,0); }
    50% { transform: translate(100px,0) ;} /* don't change for 5% of the time...pause */
    75%{transform:translate(100px,0) rotate(-90deg); }
    100% { transform: translate(100px,-100px)rotate(-90deg); }

  }
  @keyframes orbit2 {
    0% { transform: translate(0,0)rotate(0deg); }
    50% { transform: translate(200%,0)rotate(0deg); } /* don't change for 5% of the time...pause */
    75%{transform:translate(200%,-10px) rotate(-90deg) ;}
    100% { transform: translate(200%,-20px)rotate(-90deg); }

   }

   &:hover + ${ServiceTitle} {
  
      color:transparent;  
      font-size: ${(props)=>props.main?"2.6rem":"auto"};
      background-size: 100%;
      
   }
   &:hover + ${ServiceTitle}::before {
      transform: scale(1,1);
   }

    &:hover {    
 
         span{
          background-color: #E6E6E6;

          svg{
             color: #fff !important;

          }
          animation:1000ms  ${(props)=>props.down?"orbit2":"orbit1"}  750ms ease-in-out alternate infinite  ;
          ${Andriod({animationName:'orbit2'})};
         }
    }

`;
export const ArrowStyled = styled.span`
   background: linear-gradient(to right,rgb(80, 234, 123),rgb(84, 191, 191));
   padding: 0.5em 0.5em;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
 `;

// Projects Style

export const ProjectsSectionStyled = styled.section`
  padding: 4em  0 0 0;
  ${Andriod({padding:"0em 0.5em 2em 0.5em"})}
`;

export const Layer = styled.div`
  overflow: hidden;
  position: relative;
`;

export const ProjectImg = styled.img`
  transition: transform 450ms ease-in;
  width: 100%;
  overflow: hidden;
  ${Andriod({height:"auto"})}

`;

export const ProjectContainer = styled.div`
  width: min(calc(100% - 5vw), 1320px);
  margin: 0 auto;
  padding-top: 3em;
  ${Andriod({paddingTop:"0em"})};
  ${LG({ width: "min(calc(100% - 5vw), 1600px)"})}

`;
export const Projects = styled.div`
  display: flex;
  gap: 2em;
  margin-top: 2em;
  align-items: flex-start;
  ${Andriod({flexDirection:"column",marginTop:"1em"})};
`;
export const Project = styled.div`
  position: relative;
  overflow: hidden;
  &:hover ${ProjectImg} {
    transform: scale(1.2, 1.2);
  }
`;

export const Tags = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1em;
  left: -31px;
  top: -11px;
  transform: rotate(-40deg);
  z-index: 1;
  position: absolute;
  ${Andriod({flexWrap:"wrap"})};
`;
export const Tag = styled.span`
  border-radius: 0;
  width:126px;
  padding: 0.5em 1em;
  font-size: 0.9rem;
  text-align: center;
  font-family: 'Satoshi',sans-serif;
  background:linear-gradient(${({ theme }) => theme.colors.primaryGreen} 0, ${({ theme }) => theme.colors.primaryBlue});
  color: ${({ theme }) => theme.colors.primaryWhite};
  position: relative;
  overflow: hidden;
   ${Andriod({fontSize:"0.8rem"})};

`;

// Faq Section

export const Faqs = styled.div`
  padding: 1em 0 2em 0em;
  display: flex;
  transition: ease-in 350ms ease-in;
  flex-wrap: wrap;
  align-items:baseline;
  justify-content:space-between;
  transform-origin: bottom;
  transition: all 350ms ease-in;
  gap: 2em;
    ${Andriod({padding:"1em  0.5em 0 0.5em",gap:"0"})};
`;

export const FaqContaianer = styled.div`
  width: min(calc(100% - 5vw), 1320px);
  margin: 0 auto;
  position: relative;
  padding-top: 4em;
  padding-bottom: 2em;
  ${Andriod({padding:"0em 0"})};
  ${LG({ width: "min(calc(100% - 5vw), 1600px)"})}
`;

export const FaqSection = styled.section`
  background-color: ${({ theme }) => theme.colors.primaryGreyLight};
  position: relative;
  transition:all  350ms ease-in ;
  overflow: hidden;
  ${Andriod({padding:"2em 0 2em 0"})}
`;

export const FaqIcon = styled.span`
  position: absolute;
  right: 20px;
  cursor: pointer;
  ${Andriod({right:"-20px"})};
`;

export const Question = styled.h3`
  font-weight: 400;
  margin-bottom: 0.5em;
  margin-top: 0;
  font-size: 1.4rem;
  font-family: 'Satoshi',sans-serif;
  font-weight: 600;
  ${Andriod({fontSize:"1.1rem"})};
  ${LG({ fontSize: "1.6rem"})}

`;
export const Answer = styled.h3`
  font-weight: 300;
  margin-bottom: 0.5em;
  font-size: 1rem;
  transition: all 500ms cubic-bezier(0.55, 0.06, 0.68, 0.19);
  line-height: 1.7;
  color: ${({ theme }) => theme.colors.primayDarkBlack};
  ${LG({ fontSize: "1.2rem"})}

`;

export const FaqItem = styled.div`
  margin-bottom: 1em;
  margin-top: 1em;
  padding:  2em 1em 2em 1em;
  position: relative;
  background: ${({ theme }) => theme.colors.primaryWhite};
  min-height: 150px;
  width: calc(50% - 2em);
  ${Andriod({width:"100%"})};
  ${LG({ minHeight: "151px"})}
  cursor: pointer;
  border-radius: 0.2em;

  ${Answer} {
    transition: all 1s ease-in;    
    overflow: hidden;
    max-height: ${(props) => (props.selected ? "500" : 0)}px;  
    opacity: ${(props) => (props.selected ? 1 : 0)};

  }
  ${FaqIcon} {
    transition: transform 250ms ease-in;
    transform: rotate(${(props) => (props.selected === "show" ? 0.5 : 0)}turn);

  }

`;

// Testimonial Section

export const Slide = styled.div`
  padding: 1em;
  display: flex;
  min-height: 300px;
  border-radius: 0.5em;
  color: ${({ theme }) => theme.colors.primaryWhite};
`;

export const TestimonialStyled = styled.section`

  padding: 4em 0 4em 0;
  position: relative;
  background-color:#F5F5F5;
  ${TabVertical({padding:"2em 0"})}
  ${Andriod({padding:"1em 0 1em 0",overflow:"hidden"})};

  .slick-prev {
    left: 344px;
    ${TabVertical({left:"240px"})}
    ${Andriod({left:"20px"})};
    ${LG({ left: "29%"})} 
 }
 .slick-next {
   left: 394px;
   ${TabVertical({left:"290px"})}
   ${Andriod({left:"65px"})};
   ${LG({ right: "56%"})}

 }

  .slick-prev, .slick-next{
    bottom: -50px;
    ${TabVertical({bottom:"-10px"})}
    ${Andriod({bottom:"-20px"})}
 
  }

  &::before {
    position: absolute;
    content: "";
    height: 200px;
    width: 100%;
    background-color:#f8f8f8;
    z-index: -1;
  }

  h2 {
     ${LG({paddingRight:"2em"})}
  }
`;
export const TestimonialContainer = styled.div`
    width: min( calc(100% - 5vw) , 1320px );
    margin:0 auto ;
    padding: 1em 11% 3em 11%;
    ${TabVertical({padding:"1em  5%"})}
    ${Andriod({padding:"1em 1% 1em 1%"})};
    ${LG({ width: "min(calc(100% - 5vw), 1600px)"})}

`;

export const TestimonialQuote = styled.p`
  font-weight: 300;
  line-height: 1.4;
  margin:0;
  color: ${({theme})=>theme.colors.primaryBlack};
  font-size:1.4rem ;
  ${TabVertical({fontSize:"1.3rem"})}
  ${Andriod({fontSize:"1rem"})}


`;
export const TestimonailProfile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-grow: 1;
  width: 100%;
  svg {
    font-size: 60px;
     ${TabVertical({fontSize:"30px"})};
     ${Andriod({fontSize:"20px"})};
  }
  ${TabVertical({gap:"20px"})}
  ${Andriod({gap:"10px",alignItems:"center",textAlign:"center"})}
  /* margin-top: 30px; */
`;

export const TestimonailImage = styled.img`
   width: 100%;
   /* max-width: 200px; */
   border-radius: 50%;
   object-fit: contain;
   object-position: center;
   ${TabVertical({maxWidth:"150px"})}
   ${Andriod({maxWidth:"100px",width:"75px",height:"75px",margin:"0 auto"})}
`;
export const Captions = styled.div``;
export const TestimonialName = styled.h3`
  margin: 0 0 0.5em 0;
  color: #3F3F3F;
  font-weight: 400;

`;

export const Rating = styled.div``;

export const TestimonailProfession = styled.h4`
  text-transform: uppercase;
  margin: 0;
  font-size: 0.8rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primaryLighterGray};
`;

// Notice Section

export const Notice = styled.section`
   padding: 5em 3em 2em 3em;
    color:#fff;
   &.inverse {
     padding:4em 3em 12em 3em;
     ${Andriod({padding:"0em 0.6em 4em 0.6em"})}

   } 
   ${Andriod({padding:"2em 0 1em 0"})}

`;
export const NoticeContainer = styled.div`
  text-align: center;
  position: relative;
  width: min(calc(100% - 5vw), 1320px);
  margin: 0 auto;
  background-color:#0E0E15;
  padding: 0em 1em;
  ${Andriod({padding:"0em 1em"})}
  color:#fff;
  &.light {
    background:linear-gradient(to right,rgb(80, 234, 123),rgb(84, 191, 191));
    color:#fff;
  }
  &.lightest {
     background-color: #fff;
     color:#111;
  }

  ${LG({ width: "min(calc(100% - 5vw), 1600px)"})}


`;
export const BrandText = styled.h2`
  font-size: clamp(1.5rem,3.5vw,3rem);
  margin-top: 0;
  font-weight: 400;
`;
export const BrandContent = styled.h3`
 font-family: 'Satoshi',sans-serif;
  padding: 0em 4em 0em 0em;
  margin: 0em;
  font-size: 1.9rem;
  font-weight: 300;
  line-height: 1.7;
  ${Andriod({  padding:"0em",lineHeight:"1.6",fontSize:"1.1rem"})};
  text-align: left;
  font-weight: 300;
`;
export const FullRow = styled.div`
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 0em 1em 2em 1em;
  ${Andriod({padding:"0em 1em 1em 1em"})}
`;
export const NoticeRow = styled.div`
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 6em 1em 6em 1em;
  ${Andriod({padding:"2em 1em 2em 1em"})}
`;
export const RowBasic = styled.div`
  display:flex;
  flex-wrap: wrap;
  position: relative;
`
//  Blog Section
export const BlogSection = styled.section`
  padding: 4em 0;
  ${TabVertical({padding:"2em 0"})};
  ${Andriod({padding:"2em 0"})};
`;
export const BlogContainer = styled.div`
  gap: 20px;
  display: flex;
  width: min(calc(100% - 5vw), 1320px);
  margin: 2em auto 0 auto;
  align-items: flex-start;
  ${Andriod({flexDirection:"column",padding:"0 0.5em",marginTop:"0"})};
  ${LG({ width: "min(calc(100% - 5vw), 1600px)"})}

 `;
 export const AllBlogs = styled(BlogContainer)`
 `;

export const BlogImage = styled(LazyLoadImage)`
    display: block;
    transition-delay: 1s;
    height: 100%;
    transition: all 350ms ease-in;
    ${Andriod({width:"100%"})};  
`;

export const BlogUpper = styled.div`

  padding: 0 5em 0em 1em;
  transition: transform 550ms ease-in;
 
   transition:opacity 450ms ease-in;
    ${Andriod({padding:"0 0 0 1em"})}
`;

export const Blog = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 33%;
  ${Andriod({marginTop:"1em",width:"100%"})}

`;

export const BlogHeading = styled.h3`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin-bottom: 1.1em;
  font-size: clamp(${({ theme }) => theme.sizes.body}rem,1.3vw,2rem);
  font-family: 'Satoshi', sans-serif;
  font-weight: 600;

  
`;
export const BlogSubheading = styled.p`
  color: ${({ theme }) => theme.colors.primaryGrey};
  font-size: clamp(1rem,1.1vw, ${({ theme }) => theme.sizes.body}rem);
  margin-bottom:2em;
`
