import React from 'react'
import styled from 'styled-components'
const ColorLoaderStyled = styled.div`
   
            position: absolute;
            width: 100vw; /* Set the width as needed */
            height:100vh; /* Set the height as needed */
            left: 0%;
            z-index: 9999;
            top: 0%;
           background: linear-gradient(to right, #50EA7B, #54BFBF); ;       

`

const ColorLoader = () => {
  return    <ColorLoaderStyled/>

}

export default ColorLoader