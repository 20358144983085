import React, { useEffect, useRef } from 'react'

import blogImage1 from '../../assets/images/other/new-blog-1.webp';
import blogImage2 from '../../assets/images/other/new-blog-2.webp';
import blogImage3 from '../../assets/images/other/new-blog-3.webp';
import blogImagePlaceHolder from '../../assets/images/placeholders/new-blog-3.webp'


import { Blog, BlogContainer, BlogHeading, BlogImage, BlogSection, BlogSubheading, BlogUpper, SmallHeading, SubHeading, Tag, Tags } from '../../styles/home.styles';
import styled from 'styled-components';
import { Link,  useNavigate } from 'react-router-dom';

import { AnimatePresence, motion, useAnimation, useInView} from 'framer-motion';
import { Andriod } from '../../styles/responsive';
import Reveal from '../transitions/revel';


const blogData = [
  {
    id: 1,
    heading: "The Essential Role of Content Marketing",
    subheading: "Strategic planning is essential for organizations as it provides a clear sense of direction",
    tags: ["Content"],
    src: blogImage1,
    url:'/blog/the-essential-role-of-content-marketing'
  },

  {
    id: 2,
    heading: 'Design Tips for Effective Email Marketing Campaigns',
    subheading: "Marketing has been around for a long time in many different forms",
    tags: ["Campaign"],
    src: blogImage2,
    url:'/blog/tips-for-effective-email-marketing-campaigns'
  },

  {
    id: 3,
    heading: "The Rise of Progressive Web Apps (PWAs) in Digital Marketing",
    subheading: "Alright, picture this: you're cruising the internet on your phone, and suddenly...",
    tags: ["Digital"],
    src: blogImage3,
    url:'/blog/the-rise-of-progressive-web-apps-pwas'
  }
]

const OverLay = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 0.5em;
  transition:transform  350ms ease-in ;
  isolation: isolate;
  display: flex;
  min-height: 330px;
  &::before {
    position:absolute;
    inset:0;
    content: '';
    background-color: rgba(0,0,0,0.3);
    height: 100%;
    width: 100%;
   z-index: 1;


  }
  ${Andriod({minHeight:"unset"})}

`

const ReadMore = styled(Link)`
  text-decoration: none;
  position: absolute;
  bottom: 0em;
  background-image: linear-gradient( to right, #50EA7B, #54BFBF );
  color: transparent;
  left: 1em;
  background-clip: text;
  -webkit-background-clip: text;

  ${Andriod({fontSize:"clamp(0.8rem,1.1vw, 1.125rem)",left:"1.2em"})}

  &:hover + ${OverLay} img {
     transform: scale(1.2);
  }

`;


const Blogs = ({ smallHeading, bigHeading }) => {

  const ref = useRef(null);

  // const BlogMotion = motion(Blog)
  const OverLayAnimate = motion(OverLay)
  const TextAnimate = motion(BlogHeading)
  const SubTextAnimate = motion(BlogSubheading)

  const inview  = useInView(ref,{once:true,amount:"some"});
  const animate = useAnimation()



  const imageAnimate= {
    offscreen:{x:-100, opacity:0.3},
    onscreen:{
    x:0,
    opacity:1,
    rotate:[0,10,0],
    transition: {type:"spring",
    bounce:0.4,
    duration:1}
  }

}

const textAnimate={
    offscreen:{y:100, opacity:0},
    onscreen:{y:0,
    opacity:1,
    transition: {type:"spring",
    bounce:0.4,
    duration:1}
 }

}

// useEffect(()=>{
//   if(inview){
//     console.log('fire animation');
//      animate.start("onscreen")   
//    }
// },[inview])
 const naviation = useNavigate();

  return (
    <BlogSection >
      <Reveal>
        <SubHeading >{bigHeading}</SubHeading>
      </Reveal>

      <BlogContainer ref={ref} >
        <AnimatePresence onExitComplete={()=>console.log("exit")}>

        {blogData.map((blog) => {
          return <Blog onClick={()=>naviation(blog.url)} key={blog.id} data-aos-offset="230"   data-aos='fade-up' data-aos-duration={1000} data-aos-delay={blog.id * 300} 
          
                    /* variants={imageAnimate}
                    /  initial={"offscreen"}
      
                      animate={animate}
                      transition={{staggerChildren:0.5}} 
                      key={blog.id}*/
                    >
                    
            
              {/* <Tags>
              {blog.tags.map((tag, index) => {
                return <Tag key={index}>{tag}</Tag>
              })}
            </Tags> */}
            <ReadMore to={blog.url} title={blog.heading}>Read More</ReadMore>

            <OverLayAnimate variants={imageAnimate}>
              <BlogImage src={blog.src} sizes='(min-width:1023px)780px, 100vw'  effect="blur" placeholderSrc={blogImagePlaceHolder}  alt='Blog Image' />
            </OverLayAnimate>

            <BlogUpper> 
                <TextAnimate variants={textAnimate}>{blog.heading}</TextAnimate>
                <SubTextAnimate variants={SubTextAnimate}>{blog.subheading}</SubTextAnimate>
            </BlogUpper>

          </Blog>
        })}
        </AnimatePresence>

      </BlogContainer>
    </BlogSection>
  )
}

export default Blogs