import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Three3D from '../transitions/three-d-effect'
import placeHolder from '../../assets/images/placeholders/book.webp'

import { Andriod, TabVertical } from '../../styles/responsive'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const BookCover = styled(Link)`
    display: block;
    text-decoration: none ;
    position: relative;
    img  {
      max-height: 300px;
      margin: 0 auto;
      ${TabVertical({width:"200px",height:"250px",display:"block"})}
      ${Andriod({maxHeight:"200px",width:"170px"})};
      
    }
    &::before {
       position: absolute;
       content: '';
       bottom: 0;
       filter: blur(10px);
       width: 90%;
       margin: 0 auto;
       height: 2px;
       background-color:black;
    }

`
const Book = ({src,url,id}) => {


  
  return (
    
       <BookCover to={url} title='Click to visit'>
        <Three3D>
           <LazyLoadImage sizes='(min-width:1023px)780px, 100vw' srcSet={src} placeholderSrc={placeHolder} effect='blur' src={src} alt='Book'/>
        </Three3D>
       </BookCover>  
  

	
	
  )
}



export default Book